import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store.js';
import Utils from '../../Common/Utils.js';
import APICall from '../../Common/APICall.js';
import ComDecom from '../../Common/ComDecom.js';
import * as Style from '../Common/StyledComponents'
import CSLTableAlt from '../Common/CSLTableAlt'
import AlertBox from '../Common/AlertBox'
import AddGroupingDialog from './AddGroupingDialog'
import AddGroupingValueDialog from './AddGroupingValueDialog'

const MRButton = styled.button`
	float: right;
    box-sizing: border-box;
    background-color: #046DE4;
    color: #ffffff;
    border: 1px solid #37ada7;
    font-size: 13px;
    height: 35px;
    border-radius: 4px;
    margin-left: 5px;
    cursor: pointer;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-width: 70px;
    max-width: 200px;
    overflow: hidden;
`;
const MenuBar = styled.div`
    height: 35px;
    width: calc(100% - 10px);
    background-color: #F9FAFB;
    position: relative;
    color: #9CA4B2;
    border-bottom-style: solid;
    border-bottom-color:  #FEEBF4;
    border-bottom-width: 2px;
    padding-top: 3px;
    padding-left: 0px;
    margin-top: 5px;
`;


class Groupings extends React.Component
{
	state = {tags: {}, tags_config: [], show_dialog: null, grouping_id: '_new', grouping_value_index: -1, alert_param: null}

	processAPICall = (result) => {
		Utils.log('Groupings result', result)
		let tags = result.result.tags
		let tags_config = result.result.tags_config
		Store.updateStore('tags', tags)
		Store.updateStore('tags_config', tags_config)
		this.setState({tags, tags_config, show_dialog: null})
	}

	processAPICallUsersConfig = (result) => {
		Utils.log('processAPICallUsersConfig processAPICallUsersConfig result', result)
		let users_config = result.result.users_config
		Store.updateStore('users_config', users_config)
		// this.setState({users_config})
	}

	processAPICallCompanies = (result) => {
		Utils.log('GroupCompanies result', result)
		// let companies = result.result.companies
		let companies = ComDecom.deCompress(result.result.companies, false, 'id')
		let companies_lookup = {}
		for(let c of companies) {
			companies_lookup[c.id] = c
		}
		Store.updateStore('companies', companies)
		Store.updateStore('companies_lookup', companies_lookup)
		this.setState({companies, companies_lookup, alert_param:null})
	}


	setComponentState = () => {
		let tags = Store.getStoreData('tags')
		let tags_config = Store.getStoreData('tags_config')
		let users_config = Store.getStoreData('users_config')
		let companies = Store.getStoreData('companies')
		Utils.log('result Groupings setComponentState tags, tags_config, users_config', tags, tags_config, users_config)
        let api = new APICall()
        let post_data = {}
		if(users_config === null) {
        	post_data = {command: 'client_get_users_config'}
        	Utils.log('post_data', post_data)
        	api.command(post_data, this.processAPICallUsersConfig)
		}
		if(tags === null) {
	        post_data = {command: 'client_get_groupings'}
	        api.command(post_data, this.processAPICall)
        	post_data = {command: 'client_get_users_config'}
        	Utils.log('post_data', post_data)
        	api.command(post_data, this.processAPICallUsersConfig)
		} else {
			this.setState({tags, tags_config, show_dialog: null})
		}
		if(companies === null) {
	        post_data = {command: 'client_get_group_companies'}
	        api.command(post_data, this.processAPICallCompanies)
		}
	}

	componentDidMount() {
		// Utils.log('result componentDidMount')
		this.setComponentState()
	}

	componentDidUpdate(prevprops) {
		// Utils.log('result componentDidUpdate')
		if(this.props === prevprops)return

		this.setComponentState()
	}

	nameCompare = (a,b) => {
		return a.name.localeCompare(b.name)
	}

	showAddGroupingModal = (id) => {
		Utils.log('result showAddGroupingModal id', id)
		this.setState({show_dialog: 'add_grouping', grouping_id: id})
	}

	doReload = () => {
		window.location.reload(true)
	}

	deleteGroupingProcessorFinal = (result) => {
        let alert_param = {title: 'Success', message: `Removed Grouping successfully.`, ok_text: 'Ok', cancel_text: 'No', confirm: false, 
                           alertHandler: this.doReload, stack:null, body_align: 'center', danger: false}
        this.setState({alert_param: alert_param})

	}
	deleteGroupingProcessor = (result, stack) => {
		Utils.log('result, stack', result, stack)
		if(!result){this.closeDialog(); return;}

		let tags = Store.getStoreData('tags')
		let tags_config = Store.getStoreData('tags_config')
		let new_tags_config = []
		for(let t of tags_config) {
			if(Object.keys(t)[0] !== stack.grouping_id) {
				new_tags_config.push(t)
			}
		}
		if(stack.grouping_id in tags){
			delete tags[stack.grouping_id]
		}	
		Store.updateStore('tags', tags)
		Store.updateStore('tags_config', new_tags_config)
        let api = new APICall()
        let post_data = {command: 'client_save_tags_config', tags: tags, tags_config: new_tags_config}
        Utils.log('deleteGroupingProcessor post_data', post_data)
        api.command(post_data, this.deleteGroupingProcessorFinal)
	}

	deleteGrouping = (id) => {
		Utils.log('result deleteGrouping id', id)
		let tags = Store.getStoreData('tags')
		let groupings = Store.getStoreData('groupings')

		let is_empty = tags[id].length === 0 ? true : false

		let grouping_name = groupings[id]
		Utils.log('deleteGrouping grouping_name', grouping_name)

		let alert_param = {title: 'Warning', message: `Are you sure you want to remove this Grouping?`, ok_text: 'Ok', cancel_text: 'No', confirm: true, 
                           alertHandler: this.deleteGroupingProcessor, stack:{grouping_id:id}, body_align: 'center', danger: true}
        if(!is_empty) {
        	alert_param = {title: 'ERROR', message: `You cannot remove the <strong>"${grouping_name}"</strong> grouping as there are one or more ${grouping_name}(s) defined.`, ok_text: 'Ok', cancel_text: 'No', confirm: false, 
                           alertHandler: this.closeDialog, stack:null, body_align: 'center', danger: false}
        }
        this.setState({alert_param: alert_param})
	}

	closeDialog = () => {
		this.setState({show_dialog: null, alert_param: null})
		this.props.closeDialog()
		// this.setState({show_dialog: null})
	}

	showAddGroupingValueModal = (index) => {
		let grouping_id = this.props.grouping.substring(4)
		Utils.log('result index', index)
		this.setState({show_dialog: 'add_grouping_value', grouping_value_index: index, grouping_id: grouping_id})
	}

	deleteGVProcessorFinal = (result) => {
		let grouping_id = this.props.grouping.substring(4)
		let groupings = Store.getStoreData('groupings')
		let grouping_name = groupings[grouping_id]
        let alert_param = {title: 'Success', message: `Removed ${grouping_name} successfully.`, ok_text: 'Ok', cancel_text: 'No', confirm: false, 
                           alertHandler: this.closeDialog, stack:null, body_align: 'center', danger: false}
        this.setState({alert_param: alert_param})

	}

	deleteGVProcessor = (result, stack) => {
		if(!result){this.closeDialog(); return;}
		let grouping_id = stack.grouping_id
		let index = stack.index
		let tags_config = Store.getStoreData('tags_config')
		let tags = Store.getStoreData('tags')
		// Utils.log('tags, grouping_id', tags, grouping_id)
		tags[grouping_id].splice(index,1)
		Store.updateStore('tags', tags)
        let api = new APICall()
        let post_data = {command: 'client_save_tags', tags: tags, tags_config: tags_config}
        api.command(post_data, this.deleteGVProcessorFinal)

	}

	deleteGroupingValue = (index) => {
		let tags = Store.getStoreData('tags')
		let groupings = Store.getStoreData('groupings')
		let users_config = Store.getStoreData('users_config')
		let members = Store.getStoreData('members')
		let companies_lookup = Store.getStoreData('companies_lookup')
		let grouping_id = this.props.grouping.substring(4)
		let grouping_name = groupings[grouping_id]
		let tag_id = tags[grouping_id][index].id

		Utils.log('deleteGroupingValue companies_lookup', companies_lookup)

		let is_used = false
		let users = []
		for(let id in users_config) {
			if(grouping_id in users_config[id].tags && tag_id in users_config[id].tags[grouping_id]) {
				is_used = true
				users.push(members[id])
			}
		}
		// Utils.log('deleteGroupingValue users', users)
		let has_company = false
		let companies = []
		for(let cid in companies_lookup) {
			if(grouping_id in companies_lookup[cid].tags_config && companies_lookup[cid].tags_config[grouping_id] === tag_id) {
				has_company = true
				companies.push(companies_lookup[cid])
			}
		}

		let alert_param = {title: 'Warning', message: `Are you sure you want to remove this ${grouping_name}?`, ok_text: 'Yes', cancel_text: 'No', confirm: true, 
                           alertHandler: this.deleteGVProcessor, stack:{grouping_id:grouping_id, index: index}, body_align: 'center', danger: true}
        if(is_used || has_company) {
			let error_message = `<p>You cannot remove this <strong>${grouping_name}</strong> as it has existing associations.</p>`
			if(is_used) {
				error_message += `<p>Following users are assigned to this ${grouping_name}</p><ul>`
				for(let u of users) {
					error_message += `<li>${u.ContactName}</li>`
				}
				error_message += '</ul></p>'
			}
			Utils.log('deleteGroupingValue companies', companies)
			if(has_company) {
				error_message += `<p>This ${grouping_name} has the following Group Companies:</p><ul>`
				for(let c of companies) {
					error_message += `<li>${c.company_name}</li>`
				}
				error_message += '</ul></p>'
			}
        	alert_param = {title: 'ERROR', message: error_message, ok_text: 'Ok', cancel_text: 'No', confirm: false, 
                           alertHandler: this.closeDialog, stack:null, body_align: 'left', danger: false}
        }
        this.setState({alert_param: alert_param})
	}

    processGroupingData = () => {
    	let tags = this.state.tags
    	let grouping_id = this.props.grouping.substring(4)
    	let grouping_tags = grouping_id in this.state.tags ? this.state.tags[grouping_id] : []
    	// Utils.log('result processGroupingData tags, grouping_id, grouping_tags', tags, grouping_id, grouping_tags)
        // grouping_tags.sort(this.nameCompare)
              
        let ret = {data: [], columns: []};
        let i = 1;
        ret.columns =[{Header: 'Name', accessor: 'name', minWidth: 250, headerStyle: {textAlign: 'left'}},   
                        {'Header' : 'Action', Cell: row => (
                           <div>
                               <MRButton style={{width: 50, height: 25}} onClick={() => this.showAddGroupingValueModal(row.original.index)}>Edit</MRButton>
                               <MRButton style={{width: 50, height: 25}} onClick={() => this.deleteGroupingValue(row.original.index)}>Delete</MRButton>
                           </div>
                        ), headerStyle: {textAlign: 'center'}}
                     ];
                     for(let k = 0 ; k< grouping_tags.length ; k++) 
                     {  
                        let elem = {'index': k,
                                            'name': grouping_tags[k].name,
                                            'id' : grouping_tags[k].id
                                           };
                        // Utils.log('result elem', elem)
                        ret.data.push(elem);                           
                       
                      }   

        ret.data.sort(this.nameCompare)
        Store.updateStore('companies_data', ret.data)
        return ret;      
	}

    nameCompare = (a,b) => {
        return a.name.localeCompare(b.name)
    }

	render() {
		Utils.log('result Groupings', this.props)
		let grouping_id = this.props.grouping.substring(4)
		let grouping_name = Store.getStoreData('groupings')[grouping_id]
		return (<div style={{width: '100%'}}>
				<MenuBar>
					<MRButton onClick={() => this.deleteGrouping(grouping_id)} style={{backgroundColor: 'white', color: '#046DE4', fontWeight: 'bold'}}>Delete Grouping</MRButton>
					<MRButton onClick={() => this.showAddGroupingModal(grouping_id)} style={{backgroundColor: 'white', color: '#046DE4', fontWeight: 'bold'}}>Edit Grouping</MRButton>
					<MRButton onClick={() => this.showAddGroupingValueModal(-1)} title={'Add ' + grouping_name} style={{ fontWeight: 'bold'}}>Add {grouping_name}</MRButton>
				</MenuBar>
				<CSLTableAlt add={false} processData={this.processGroupingData} headerText={null} tableRows={20} tableWidth='calc(100% - 10px)' />
				{
					(() => {
						if(this.state.show_dialog !== null) {
							switch(this.state.show_dialog) {
								case 'add_grouping': return (<AddGroupingDialog 
																closeDialog={this.closeDialog} 
																closeDialogSave={this.props.closeDialogSave} 
																editGroup={this.state.grouping_id} />); break;
								case 'add_grouping_value': return (<AddGroupingValueDialog 
																	closeDialog={this.closeDialog} 
																	editGroup={this.state.grouping_id}
																	index={this.state.grouping_value_index} />); break;
							}
						}
					})()
				}
				<AlertBox alertParam={this.state.alert_param} />
			</div>)
	}
}

export default Groupings;