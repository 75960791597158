import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store.js';
import Utils from '../../Common/Utils.js';
import APICall from '../../Common/APICall.js';
import * as Style from '../Common/StyledComponents'
import CSLTableAlt from '../Common/CSLTableAlt'
import AlertBox from '../Common/AlertBox'
import CSLCheckBox from '../Common/CSLCheckBox'
import ComDecom from '../../Common/ComDecom.js';
import {FaPlus, FaPencilAlt, FaTimes, FaEllipsisV, FaUser} from 'react-icons/fa';

const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #046DE4;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 100px;
    font-size: 14px;
    height: 35px;
    border-radius: 4px;
    margin-left: 5px;
    margin-top: 5px;
    cursor: pointer;
`;
const MenuBar = styled.div`
    height: 35px;
    width: calc(100% - 10px);
    background-color: #F9FAFB;
    position: relative;
    color: #9CA4B2;
    border-bottom-style: solid;
    border-bottom-color:  #FEEBF4;
    border-bottom-width: 2px;
    padding-top: 3px;
    padding-left: 0px;
    margin-top: 5px;
`;
const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 100vh;
  width: calc(100vw - 330px);
  background-color: #ffffff;
  position: fixed;
  left: 300px;
  top: 80px;
  z-index: 1000;
  opacity: 0.8;
`;

const ModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: calc(100vh - 160px);
    width: calc(100vw - 340px);
    position: fixed;
    top: 150px;
    left: 310px;
    z-index: 1001;
    background-color: #F3F7FB;
    box-shadow: 0 0 5px #a7a6a6;
    margin-bottom: 10px;
    border-radius: 5px;
    background-color: white;
`;


const ModalHeader = styled.div`
    background-color: white;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 14px;
    font-weight: 700;
    height: 60px;
    color: #046DE4;
    width: calc(100% - 12px);
`;
const ModalBody = styled.div`
    position: relative;
    display: block;
    box-sizing: border-box;
    background-color: #F3F7FB;
    padding: 10px;
    border-radius: 4px;
    height: calc(100vh - 280px);
    overflow-y: auto;
`;
const ModalBodyInner = styled.div`
    border-radius: 4px;
    padding: 15px;
    background-color: #ffffff;
`;
const ModalLabel = styled.div`
    color: #242424;
    font-weight: 600;
    margin-top: 10px;
    font-size: 16px;
    height: 20px;
    padding-bottom: 5px;
    margin-bottom: 5px;
`;
const ModalInput = styled.input`
    height: 40px;
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    font-family: 'Montserrat', sans-serif;
    color: #242424;
`;


const ModalHeaderText = styled.div`
    float: left;
    font-weight: bold;
    box-sizing: border-box;
    font-size: 24px;
    margin-top: 16px;
    padding-left: 25px;
    background-color: white;
    color: #046DE4;
    font-family: 'Montserrat', sans-serif;
`;

// const ModalBody = styled.div`
//     position: relative;
//     display: block;
//     box-sizing: border-box;
//     background-color: #F3F7FB;
//     padding: 10px;
//     border-radius: 4px;
// `;
const ModalHeaderCloseBtn = styled.div`
    float: right;
    box-sizing: border-box;
    margin-top: 16px;
    margin-right: 20px;
    color: #949494;
    font-size: 16px;
    cursor: pointer;
`;
const MRModalLabel = styled.label`
    color: #a0a0a0;
    font-weight: 600;
    margin-top: 10px;
`;
const MRModalInput = styled.input`
    height: 40px;
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #979797;
`;
const MRModalBackBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #909090;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    border: 2px solid #dadada;
    margin-left: 5px;
    font-size: 11px;
    margin-right: 10px;
    float: right;
`;

const MRModalFooter = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 20px;
`;
const MRModalCancelBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #909090;
    border: 2px solid #dadada;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    font-size: 11px;
    float: right;
`;
const MRModalNextBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    margin-left: 5px;
    font-size: 11px;
    margin-left: 10px;
    float: right;
`;
const MRModalSelect = styled.select`
    display: block;
    box-sizing: border-box;
    height: 40px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    color: #242424;
`;

class AddGCDialog extends React.Component {
    state = {gc_id: '_new', name: '', alert_param: null, tags_config: {}, defacto_users: [], users_config: {}}

    componentDidMount = () => {
        // this.processDefactoUsers()
        let companies_lookup = Store.getStoreData('companies_lookup')
        let name = this.props.editGC in companies_lookup ? companies_lookup[this.props.editGC].company_name : ''
        let tags = Store.getStoreData('tags')
        let tags_config = Store.getStoreData('tags_config')
        Utils.log('companies_lookup[this.props.editGC]', companies_lookup[this.props.editGC])
        let com_tags_config = {}
        for(let t of tags_config) {
            let tid = Object.keys(t)[0]
            com_tags_config[tid] = '0'
        }
        com_tags_config = this.props.editGC in companies_lookup ? companies_lookup[this.props.editGC].tags_config : com_tags_config
        let users_config = Store.getStoreData('users_config')
        let members = Store.getStoreData('members')
        Utils.log('users_config', users_config)
        let init_tags = {}
        for(let tc of tags_config) {
            let tag_id = Object.keys(tc)[0]
            init_tags[tag_id] = {}
        }
        for(let m_id in members) {
            if(!(m_id in users_config)) {
                users_config[m_id] = {companies: {}, tags: init_tags}
            }
        }
        let groupings = Store.getStoreData('groupings')
        let tags_lookup = Store.getStoreData('tags_lookup')
        let defacto_users = []
        Utils.log('processDefactoUsersData groupings, tags_lookup, this.state.tags_config', groupings, tags_lookup, this.state.tags_config)

        for(let g_id in com_tags_config) {
            if(com_tags_config[g_id] !== '0') {
                for(let u_id in users_config) {
                    if(g_id in users_config[u_id].tags && com_tags_config[g_id] in users_config[u_id].tags[g_id] && u_id in members) {
                        let t_id = com_tags_config[g_id]
                        defacto_users.push({name: members[u_id].ContactName, grouping: groupings[g_id], grouping_tag: tags_lookup[g_id][t_id]})
                    }
                }
            }
        }
        Utils.log("defacto_users", defacto_users)
        Utils.log("members", members)
        this.setState({gc_id: this.props.editGC, name: name, tags: tags, tags_config: com_tags_config, defacto_users: defacto_users, users_config: users_config})
    }

    handleChange = (evt) => {
        this.setState({[evt.target.name]: evt.target.value});
    }

    handleTagChange = (evt) => {
        // const value = evt.target.value;
        let tags_config = this.state.tags_config
        let tag_id = evt.target.name
        tags_config[tag_id] = evt.target.value
        let users_config = Store.getStoreData('users_config')
        let members = Store.getStoreData('members')
        let tags = Store.getStoreData('tags')
        let groupings = Store.getStoreData('groupings')
        let tags_lookup = Store.getStoreData('tags_lookup')
        let defacto_users = []
        Utils.log('processDefactoUsersData groupings, tags_lookup, this.state.tags_config', groupings, tags_lookup, this.state.tags_config)

        for(let g_id in tags_config) {
            if(tags_config[g_id] !== '0') {
                for(let u_id in users_config) {
                    if(g_id in users_config[u_id].tags && tags_config[g_id] in users_config[u_id].tags[g_id]) {
                        let t_id = this.state.tags_config[g_id]
                        defacto_users.push({name: members[u_id].ContactName, grouping: groupings[g_id], grouping_tag: tags_lookup[g_id][t_id]})
                    }
                }
            }
        }



        this.setState({tags_config, defacto_users});
    }

    saveFormProcessorFinal = () => {
        this.setState({alert_param: null})
        this.props.closeDialog()
    }

    saveFormProcessor = (result) => {
        Utils.log('companies result', result)
        let companies = ComDecom.deCompress(result.result.companies, false, 'id')


        Store.updateStore('companies', companies)
        let companies_lookup = {}
        for(let c of companies) {
            companies_lookup[c.id] = c
        }
        Store.updateStore('companies_lookup', companies_lookup)
        let alert_param = {title: 'Success', message: `Group company saved.`, ok_text: 'Ok', cancel_text: 'No', confirm: false, 
                           alertHandler: this.saveFormProcessorFinal, stack:null, body_align: 'center', danger: false}
        this.setState({alert_param: alert_param})
    }

    saveForm = () => {
        // let companies = Store.getStoreData('companies')
        let tags_config = this.state.tags_config
        let post_data = {
                            command: 'client_save_company',
                            id: this.state.gc_id, 
                            users_config: this.state.users_config,
                            company_data: {company_name: this.state.name, tags_config: tags_config}, 
                            is_new: this.state.gc_id === '_new'
                        }

        // Store.updateStore('tags', tags)
        // Store.updateStore('groupings', groupings)
        Store.updateStore('users_config', this.state.users_config)
        let api = new APICall()
        // let post_data = {command: 'client_save_tags_config', tags: tags, tags_config: tags_config}
        api.command(post_data, this.saveFormProcessor)
    }

    processDefactoUsersData = (param) => {
        let users_config = Store.getStoreData('users_config')
        let tags_config = Store.getStoreData('tags_config')
        let members = Store.getStoreData('members')
        let tags = Store.getStoreData('tags')
        let groupings = Store.getStoreData('groupings')
        let tags_lookup = Store.getStoreData('tags_lookup')
        Utils.log('processDefactoUsers tags_lookup', tags_lookup)
        let defacto_users = this.state.defacto_users
        let ret = {data: [], columns: []};
        let i = 1;
        ret.columns =[{Header: 'Name', accessor: 'name', width: '33%', headerStyle: {textAlign: 'left'}},   
            {'Header' : 'Grouping', accessor: 'grouping', width: '33%', headerStyle: {textAlign: 'left'}},
            {'Header' : 'Grouping Tag', accessor: 'grouping_tag', width: '33%', headerStyle: {textAlign: 'left'}}
        ];

        for(let d of defacto_users) 
        {  
            i++
            let elem = {'index': i,
                                'name': d.name,
                                'grouping' : d.grouping,
                                'grouping_tag' : d.grouping_tag
                               };
            ret.data.push(elem);                           
        }  
        Utils.log('ret', ret)
        ret.data.sort(this.nameCompare)

        return ret
    }

    assignUser = (check, param) => {
        let users_config = this.state.users_config
        if(check) users_config[param.id].companies[this.state.gc_id] = 1
        else delete users_config[param.id].companies[this.state.gc_id]

        this.setState({users_config})
    }

    processGCUsersData = () => {
        let users_config = this.state.users_config
        let members = Store.getStoreData('members')
        let ret = {data: [], columns: []};

        ret.columns =[  {Header: '#', accessor: 'index', width: 120, headerStyle: {textAlign: 'left', width: 120}}, 
                        {Header: 'Name', accessor: 'name', headerStyle: {textAlign: 'left'}}, 
                        {'Header' : 'Assign/Deassign', Cell: row => (
                           <div>
                                {
                                    (() => {
                                        return (<CSLCheckBox 
                                                    param={{id: row.original.id}} 
                                                    checkCallback={this.assignUser}
                                                    checked={row.original.is_assigned} 
                                                    style={{borderColor: ''}} />)
                                    })()                                       
                                }
                           </div>
                        ), headerStyle: {textAlign: 'left', maxWidth: 180, width: 180}, width: 180}];
        let k = 0
        for(let uid in users_config) {
            if(this.state.gc_id in users_config[uid].companies && uid in members) {
                k++
                let elem = {index: uid, name:members[uid].ContactName, id: uid, is_assigned: this.state.gc_id in users_config[uid].companies }
                ret.data.push(elem)
            }
        }

        ret.data.sort(this.nameCompare)
        k = 0
        for(let r of ret.data) {
            k++
            r.index = k
        }
        return ret
    }

    processGCUnassignedUsersData = () => {
        let users_config = this.state.users_config
        let members = Store.getStoreData('members')
        let ret = {data: [], columns: []};

        ret.columns =[  {Header: '#', accessor: 'index', width: 120, headerStyle: {textAlign: 'left', width: 120}}, 
                        {Header: 'Name', accessor: 'name', headerStyle: {textAlign: 'left'}}, 
                        {'Header' : 'Assign/Deassign', Cell: row => (
                           <div>
                                {
                                    (() => {
                                        return (<CSLCheckBox 
                                                    param={{id: row.original.id}} 
                                                    checkCallback={this.assignUser}
                                                    checked={row.original.is_assigned} 
                                                    style={{borderColor: ''}} />)
                                    })()                                       
                                }
                           </div>
                        ), headerStyle: {textAlign: 'left', maxWidth: 180, width: 180}, width: 180}];
        let k = 0
        for(let uid in users_config) {
            if(!(this.state.gc_id in users_config[uid].companies) && uid in members) {
                k++
                let elem = {index: uid, name:members[uid].ContactName, id: uid, is_assigned: false }
                ret.data.push(elem)
            }
        }

        ret.data.sort(this.nameCompare)
        k = 0
        for(let r of ret.data) {
            k++
            r.index = k
        }
        return ret
    }

    nameCompare = (a,b) => {
        return a.name.localeCompare(b.name)
    }

    render() {
        let tags = Store.getStoreData('tags')
        let tags_config = Store.getStoreData('tags_config') === null ? [] : Store.getStoreData('tags_config')

        let users_config = this.state.users_config
        let members = Store.getStoreData('members')

        let un_assign_count = 0;
        let assign_count = 0;
        for(let uid in users_config) {
            if(this.state.gc_id in users_config[uid].companies && uid in members) {
                assign_count++
            }else if(!(this.state.gc_id in users_config[uid].companies) && uid in members){
                un_assign_count++
            }
        }
        return (<div>
              <ModalContainer>
                <ModalHeader>
                    <ModalHeaderText>{this.state.gc_id === '_new' ? 'New Company' : 'Edit Company'}</ModalHeaderText>
                    <Style.ModalHeaderCloseBtn onClick={() => this.props.closeDialog()}><FaTimes /></Style.ModalHeaderCloseBtn>
                </ModalHeader>
                <ModalBody>
                    <ModalBodyInner>
                        <ModalLabel>Company name</ModalLabel>
                        <ModalInput type="text" name="name" onChange={this.handleChange} value={this.state.name} />
                        <div>
                        {
                            tags_config.map((k,i) => {
                                let id = Object.keys(k)[0]
                                let current_tags = tags[id]
                                current_tags.sort(this.nameCompare)
                                return (
                                    <div key={i} style={{float: 'left'}}>
                                        <ModalLabel>{k[id]}</ModalLabel>
                                        <MRModalSelect onChange={this.handleTagChange} name={id} value={this.state.tags_config[id]} style={{width: "350px"}}>
                                            <option value="0">None</option>
                                            {
                                                current_tags.map((t,j) => {
                                                    return <option key={j} value={t.id}>{t.name}</option>
                                                })
                                            }
                                        </MRModalSelect>
                                    </div>
                                    )
                            })
                        }
                        </div>
                        <div style={{clear: 'both'}} />
                        <ModalLabel>Groupings based De Facto Users</ModalLabel>
                        <CSLTableAlt add={false} 
                            processData={this.processDefactoUsersData} 
                            headerText={null} 
                            tableRows={this.state.defacto_users.length + 2} 
                            tableWidth='calc(100% - 10px)' 
                        />
                        {
                            (() => {
                                if(this.state.gc_id !== '_new') {
                                    return (<div>
                                                <ModalLabel>Directly assigned Users</ModalLabel>
                                                <CSLTableAlt add={false} 
                                                    processData={this.processGCUsersData} 
                                                    headerText={null} 
                                                    tableRows={assign_count + 2} 
                                                    tableWidth='calc(100% - 10px)' 
                                                />
                                            </div>
                                        )
                                }
                            })()
                        }  
                        {
                            (() => {
                                if(this.state.gc_id !== '_new') {
                                    return (<div>
                                                <ModalLabel>Unassigned users</ModalLabel>
                                                <CSLTableAlt add={false} 
                                                    processData={this.processGCUnassignedUsersData} 
                                                    headerText={null} 
                                                    tableRows={un_assign_count + 2} 
                                                    tableWidth='calc(100% - 10px)' 
                                                />
                                            </div>
                                        )
                                }
                            })()
                        }                   </ModalBodyInner>
                </ModalBody>
                <Style.ModalHeader>
                    <MRButton style={{float: 'right'}} onClick={() => this.saveForm()}>Save</MRButton>
                    <MRButton style={{float: 'right', backgroundColor: 'white', color: '#046DE4'}} onClick={() => this.props.closeDialog()}>Cancel</MRButton>
                </Style.ModalHeader>
              </ModalContainer>
              <AlertBox alertParam={this.state.alert_param} />
            </div>)
    }
}

export default AddGCDialog;
