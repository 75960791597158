import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store'
import { FaTasks } from "react-icons/fa";
import { TiArrowBack } from "react-icons/ti";
import * as Style from './StyledComponents'

class TaskHeader extends React.Component
{
    render()
    {
        let config_url = '/';
        return (
            <Style.HeadContainer>
                <Style.HomeLogo><FaTasks /></Style.HomeLogo>
                <Style.HomeText><span style={{fontWeight: "600"}}>Task Manager: Filechecker Task Viewer</span></Style.HomeText>
                <Style.Categories><a href={config_url}><TiArrowBack /></a></Style.Categories>
                <div style={{clear: "both"}}></div>
            </Style.HeadContainer>
        );
    }
}
export default TaskHeader;
