import Store from './Store.js';
import Utils from './Utils.js';
import http from 'mcc-front-aux/dist/httpCommon'
class APICall {
    constructor() {
        this.url = Store.getStoreData('api_url');
        this.is_admin = Store.getStoreData('is_admin');
        this.is_debug = Store.getStoreData('is_debug');
        this.module_name = Store.getStoreData('module_name');
    }

    command(postData, callback) {
        postData.env = "select";

        let jsonDataString = JSON.stringify(postData).replace(/&/g, '--and--');
        jsonDataString = jsonDataString.replace(/\+/g, '--plus--');
        jsonDataString = jsonDataString.replace(/'+/g, '--quote--');
        jsonDataString = jsonDataString.replace(/\n/g, '');
        jsonDataString = jsonDataString.replace(/\r/g, '');

        this.url = Store.getStoreData('api_url');

        // Add in the json command if it exists
        let postURL = this.url
        if('command' in postData) {
            postURL += `/${postData.command}`
        }

        http.post(postURL, {jsondata: jsonDataString}).then(res => {

            // Everything else is unchanged from the below (now omitted) fetch code, except for the first line.
            // The res.data.body field is equivalent to what they've been expecting from JSON.stringify(result); so, replace result = JSON.stringify(result) like so.
            res = res.data.body

            // Unchanged from the body of code you can see below
            res = res.replace(/(--plus--)/g, "+");
            res = res.replace(/(--quote--)/g, "'");
            res = res.replace(/(--and--)/g, "&");
            res = res.replace(/'+/g, "'");
            res = JSON.parse(res);
            callback(res);
        }).catch(error => {
            console.error(error);
            return null;
        });

    }
}

export default APICall;
