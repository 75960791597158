import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store.js';
import Utils from '../../Common/Utils.js';
import APICall from '../../Common/APICall.js';
import * as Style from '../Common/StyledComponents'
import CSLTableAlt from '../Common/CSLTableAlt'
import AlertBox from '../Common/AlertBox'
import AddGCDialog from './AddGCDialog'
import ComDecom from '../../Common/ComDecom.js';
import CSLCheckBox from '../Common/CSLCheckBox'

import moment from 'moment';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
const fileExtension = '.xlsx';
const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #046DE4;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 180px;
    font-size: 13px;
    height: 35px;
    border-radius: 4px;
    margin-left: 5px;
    font-weight: bold;
    cursor: pointer;
    float: right;
`;
const MenuBar = styled.div`
    height: 35px;
    width: calc(100% - 10px);
    background-color: #F9FAFB;
    position: relative;
    color: #9CA4B2;
    border-bottom-style: solid;
    border-bottom-color:  #FEEBF4;
    border-bottom-width: 2px;
    padding-top: 3px;
    padding-left: 0px;
    margin-top: 5px;
`;


class GroupCompanies extends React.Component
{
	state = {companies: [], companies_lookup: {}, show_dialog: null, gc_id: '', alert_param: null}

	processAPICall = (result) => {
		Utils.log('GroupCompanies result', result)
		let companies = ComDecom.deCompress(result.result.companies, false, 'id')
		let companies_lookup = {}
		for(let c of companies) {
			companies_lookup[c.id] = c
		}
		Utils.log('companies', companies)
		Store.updateStore('companies', companies)
		Store.updateStore('companies_lookup', companies_lookup)
		this.setState({companies, companies_lookup, alert_param:null})
	}

	processAPICallUsersConfig = (result) => {
		Utils.log('GroupCompanies processAPICallUsersConfig result', result)
		let users_config = result.result.users_config
		Store.updateStore('users_config', users_config)
		this.setState({users_config})
	}

	setComponentState = () => {
		let companies = Store.getStoreData('companies')
		let users_config = Store.getStoreData('users_config')
		if(companies === null) {
	        let api = new APICall()
	        let post_data = {command: 'client_get_group_companies'}
	        api.command(post_data, this.processAPICall)
	        if(users_config === null){
	        	post_data = {command: 'client_get_users_config'}
	        	Utils.log('post_data', post_data)
	        	api.command(post_data, this.processAPICallUsersConfig)
	        }
	        
		} else {
			this.setState({companies})
		}
	}

	refreshData = () => {
        let api = new APICall()
        let post_data = {command: 'client_get_group_companies'}
        api.command(post_data, this.processAPICall)		
	}

	componentDidMount() {
		this.setComponentState()
	}

	componentDidUpdate(prevprops) {
		if(this.props === prevprops)return

		this.setComponentState()
	}

	nameCompare = (a,b) => {
		return a.company_name.localeCompare(b.company_name)
	}

	showAddGroupCompanyModal = (id) => {
		Utils.log('result showAddGroupCompanyModal id', id)
		this.setState({show_dialog: 'add_gc', gc_id: id.toString()})
	}

	exportUsergc = () => {
		let users_config = Store.getStoreData('users_config')
		let companies = Store.getStoreData('companies_lookup')
		let members = Store.getStoreData('members')
		let wb = { SheetNames: [], Sheets: {} };
		let x = []
		for(let c in companies) {
			let company_name = companies[c].company_name.replace(/--and--/g,'&');
			company_name = company_name.replace(/--plus--/g,'+');
			company_name = company_name.replace(/'/g, "''");
			for(let uid in users_config) {
			    let included = companies[c].id in users_config[uid].companies
			    if(uid in members) {
			        let member_name = members[uid].ContactName.replace(/--and--/g,'&');
			        member_name = member_name.replace(/--plus--/g,'+');
			        member_name = member_name.replace(/'/g, "''");
			        if(included)x.push({"Group Company": company_name, "User": member_name})
			    }
			}
		}

		let ws = XLSX.utils.json_to_sheet(x);
		XLSX.utils.book_append_sheet(wb, ws, "Group Company");
		let format = "DD-MM-YYYY HH:mm:ss"
		let tm = moment().format(format);
		const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
		const data = new Blob([excelBuffer], { type: fileType });
		FileSaver.saveAs(data, 'Group Company' + '-' + tm + fileExtension);

	}

	closeDialog = () => {
		this.setState({show_dialog: null, alert_param: null})
		this.props.closeDialog()
	}

	deleteGCProcessorFinal = (result) => {
        let alert_param = {title: 'Success', message: `Removed Group Company`, ok_text: 'Ok', cancel_text: 'No', confirm: false, 
                           alertHandler: this.refreshData, stack:null, body_align: 'center', danger: false}
        this.setState({alert_param})

	}

	deleteGCProcessor = (result,stack) => {
		if(!result){this.closeDialog(); return;}
        let post_data = {command: 'client_remove_gc',gc_id: stack.id}
        let api = new APICall()
        api.command(post_data, this.deleteGCProcessorFinal)

	}

	deleteGC = (id) => {
		// Utils.log('result deleteGC id', id)
		let alert_param = {title: 'Confirm', message: `Deleting a Group Company may cause data issues where Users and Module Entities like File Checks, Compliance Advice, etc. are associated with this Group Company. Please delete this Group Company only if you know it has not been used or consider renaming it instead.`, ok_text: 'Confirm', cancel_text: 'Cancel', confirm: true, 
                           alertHandler: this.deleteGCProcessor, stack:{id:id}, body_align: 'center', danger: false}

        let users_config = Store.getStoreData('users_config')
        let companies = Store.getStoreData('companies_lookup')
        let groupings = Store.getStoreData('groupings')
        let members = Store.getStoreData('members')
        let tags = Store.getStoreData('tags')
        let tags_lookup = {}
        for(let t in tags) {
        	tags_lookup[t] = {}
        	for(let v of tags[t]) {
        		tags_lookup[t][v.id] = v.name
        	}
        }
        users_config = Object.keys(users_config).length > 0 ? users_config : {}
        let has_users = false
        let has_groupings = false
        let gc_users = []
        let gc_groupings = []
        Utils.log('deleteGC tags', tags, members)
        for(let uid in users_config) {
        	if(id in users_config[uid].companies && uid in members){
        		has_users = true
        		gc_users.push(members[uid])
        	}
        }
        for(let tid in groupings) {
        	// Utils.log('tid, companies[id].tags_config', tid, companies[id].tags_config)
        	if(tid in companies[id].tags_config && companies[id].tags_config[tid] !== '0'){
        		has_groupings = true
        		gc_groupings.push({id: tid, name: groupings[tid], value_id: companies[id].tags_config[tid]})
        	}
        }
        // console.log("has_users", has_users, has_groupings)
        // console.log("gc_users", gc_users)
        if(has_users || has_groupings) {
        	let error_message = `<p>You cannot remove <strong>${companies[id].company_name}</strong> as it has associations with other entities.</p>`
        	if(has_groupings) {
        		error_message += `<p>The company belongs to the following <strong>Groupings</strong>:</p><p><ul>`
        		for(let g of gc_groupings) {
        			error_message += `<li>${g.name}: ${tags_lookup[g.id][g.value_id]}</li>`
        		}
        		error_message += '</ul></p>'
        	}
        	if(has_users) {
        		error_message += `<p>The company has the following <strong>Users</strong>:</p><p><ul>`
        		for(let u of gc_users) {
        			error_message += `<li>${u.ContactName}</li>`
        		}
        		error_message += '</ul></p>'
        	}
        	alert_param = {title: 'ERROR', message: error_message, ok_text: 'Ok', cancel_text: 'No', confirm: false, 
                           alertHandler: this.closeDialog, stack:{id:id}, body_align: 'left', danger: false}

        }

        
        this.setState({alert_param: alert_param})
	}

	associategcUsers = (gc_id) => {
		
		let users_config = Store.getStoreData('users_config')
		let members = Store.getStoreData('members')
		let assign_users = {}
		for(let uc in users_config){
			if(uc in members && gc_id in users_config[uc].companies){
				assign_users[uc] = 1
			}
		}
		let count = Object.keys(assign_users).length;
		// console.log("users_config", members)
		return count
	}

    processGCData = () => {
    	let companies = this.state.companies
        companies.sort(this.nameCompare)
              
        let ret = {data: [], columns: []};
        let i = 1;
        ret.columns =[{Header: 'Name', accessor: 'company_name', minWidth: 250, headerStyle: {textAlign: 'left'}}, 
        	{'Header' : 'Contacts', accessor: 'assign_user', filterable: false, sortable: false, headerStyle: {textAlign: 'left'}},
        	{'Header' : 'Active', width:180, Cell: row => (
        	   <div>
        	        {
        	            (() => {
        	                return (<CSLCheckBox 
        	                			param={{id: row.original.id}}
        	                			checkCallback={this.activedeactiveGC}
        	                			checked={row.original.is_active}
        	                			style={{borderColor: ''}} 
        	                			/>)

        	            })()
        	                
        	        }
        	   </div>
        	), headerStyle: {textAlign: 'left', width: 180}},
            {'Header' : 'Action', filterable: false, sortable: false, Cell: row => (
               <div>
                   <MRButton style={{width:70, height: 25, fontWeight: 400}} onClick={() => this.showAddGroupCompanyModal(row.original.id)}>Edit</MRButton>
                   <MRButton style={{width:70, height: 25, fontWeight: 400}} onClick={() => this.deleteGC(row.original.id)}>Delete</MRButton>
               </div>
            ), headerStyle: {textAlign: 'center'}}
         ];
         for(let k = 0 ; k< companies.length ; k++) 
         {  
            let elem = {'index': k,
                                'company_name': companies[k].company_name,
                                'id' : companies[k].id,
                                'is_active' : companies[k].is_active,
                                'assign_user' : this.associategcUsers(companies[k].id)
                               };
            ret.data.push(elem);                           
           
          }   
        Store.updateStore('companies_data', ret.data)
        return ret;      
	}

	activedeactiveGC = (check, param) => {
	    let id = param.id
	    let post_data = {command: 'active_deactive_gc',gc_id: id}
	    post_data['check'] = check ? 1 : 0;
	    let api = new APICall()
	    api.command(post_data, this.activeDeactiveGC)
	}

	activeDeactiveGC = (result) => {
		let rs = result.result;
		let id = rs.id;
		let is_active = rs.is_active;
		let companies = this.state.companies;
		for(let k in companies) {
			if(companies[k].id === id){
				companies[k].is_active = is_active;
			}
		}
		this.setState({companies})
		Store.updateStore('companies', companies)

	}
	render() {
		return (<div style={{width: '100%'}}>
				<MenuBar>
					<MRButton onClick={() => this.showAddGroupCompanyModal('_new')} style={{ fontWeight: 'bold'}}>Add Group Company</MRButton>
					<MRButton onClick={() => this.exportUsergc()} style={{ fontWeight: 'bold'}}>Export</MRButton>
				</MenuBar>
				<CSLTableAlt add={false} processData={this.processGCData} headerText={null} tableRows={20} tableWidth='calc(100% - 10px)' />
				{
					(() => {
						if(this.state.show_dialog !== null) {
							switch(this.state.show_dialog) {
								case 'add_gc' : return <AddGCDialog closeDialog={this.closeDialog} editGC={this.state.gc_id} />; break;
							}
						}
					})()
				}
				<AlertBox alertParam={this.state.alert_param} />
			</div>)
	}
}

export default GroupCompanies;