import React from 'react'
import styled from 'styled-components'
import {FaPlus} from 'react-icons/fa'
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import Pagination from "./Pagination";

import "./alttable_styles.css";

const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 95px;
    font-size: 13px;
    height: 25px;
    border-radius: 0px;
    margin-right:1px;
`;
const HeaderText = styled.div`
    padding:12px;
    color: #212121;
    font-weight: 600;
    letter-spacing: 1px;
`;
const TableHeaderIcon = styled.div`
    float: right;
    margin-right: 20px;
    margin-top: 15px;
    color: #c0c0c0;
`;

const TextPara = styled.div`
    padding: 15px 15px 15px 15px;
    color: #c0c0c0;
`
const TableContainer = styled.div`

`

class CSLTableAlt extends React.Component
{
    state = {width: '100%', count: 0, row_num: 10, table_data: {data: [], columns: []}}

    count = 0
    constructor(props) {
        super(props);
    }

    setComponentState = () => {
        let data_params = 'processDataParams' in this.props ? this.props.processDataParams : null;
        let table_data = this.props.processData(data_params);
        let width = 'tableWidth' in this.props ? this.props.tableWidth : this.state.width;
        let row_num = this.props.tableRows === null ? table_data.data.length : this.props.tableRows
        // console.log('setComponentState table')
        this.count++
        this.setState({width, row_num, table_data, count: this.count})        
    }

    componentDidMount = () => {
        // console.log('componentDidMount table')
        this.setComponentState()
    }

    componentDidUpdate = (prevprops) => {

        if(this.props === prevprops)return
        // console.log('componentDidUpdate table')
        this.setComponentState()
    }

    filterMethod = (filter, row, column) => {
        const id = filter.pivotId || filter.id
        // console.log('id',id);
        return row[id] !== undefined ? String(row[id]).includes(filter.value) : true
    }


    render() {
        // console.log('this.props', this.props);
        return (
            <TableContainer style={{width: this.state.width}}>
            {
                (() => {
                    if('headerText' in this.props && this.props.headerText !== null){
                        return <HeaderText>{this.props.headerText}</HeaderText> 
                    }
                })()
            }
                                                         
                    <div style={{ marginTop: '5px', width: '100%', backgroundColor: '#FFF' }}>
                        <ReactTable

                            data={this.state.table_data.data}
                            columns={this.state.table_data.columns}
                            pageSize={parseInt(this.props.tableRows)}
                            showPagination={true}
                            defaultPageSize={5}
                            filterable={true}
                            PaginationComponent={Pagination}
                            defaultFilterMethod={(filter, row, column) => {
                                const id = filter.pivotId || filter.id
                                console.log('id',id);
                                return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true
                            }}
                        />
                    </div>
            </TableContainer>
            );
    }
}

export default CSLTableAlt;

                            
                            