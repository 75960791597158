import React from 'react';
import styled from 'styled-components';
import Store from './Common/Store.js';
import APICall from './Common/APICall.js';
import Utils from './Common/Utils.js';
import Index from './Components/Index';
import ClientIndex from './Components/Client/ClientIndex';
import CSLLoader from './Components/Common/CSLLoader'
import CSLPageloader from './Components/Common/CSLPageloader';
import ComDecom from './Common/ComDecom.js';
import AlertBox from './Components/Common/AlertBox'
// import ConfigIndex from './Components/ConfigIndex';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import authService from "mcc-front-aux/dist/authService";

import Reload from './Common/Reload.js';

/*global localStorage*/

const moment = require('moment');
const last_action_time = moment().unix()

class AppContent extends React.Component
{
	state = { ready: false, alert_param: null };

	constructor(props) {
		super(props);
        Utils.setEnv('gconfig');
        Store.updateStore('module_name', 'gconfig');
    }

    async refreshState(){
        let postData = { command: "index" };
        let api = new APICall();
        await api.command(postData, this.processIndexData);
    }

    async componentDidMount() {
        console.log("is_admin", Store.getStoreData('is_admin'))

        if(Store.getStoreData('is_admin')){
            let postData = { command: "index" };
            let api = new APICall();
            await api.command(postData, this.processIndexData);
        } else {
            this.setComponentState()
        }
    }


    setComponentState = () => {
		let members = Store.getStoreData('members')
		if(members === null) {
            let api = new APICall()
	        let post_data = {command: 'client_get_contacts'}
	        api.command(post_data, this.processAPICall)
		}
	}

    processAPICall = (result) => {
        Utils.log('AssignUsers result', result)
        if (result.error_code === 0) {
            if (result.role === 'no_access' || result.role === 'team') {
                let alert_param = {
                    title: 'No Access',
                    message: 'You are not authorised to access this module. If you think this is in error, please contact your administrator.',
                    ok_text: 'OK',
                    confirm: false,
                    alertHandler: this.alertHandler,
                    stack: { id: 0 }
                };
                this.setState({ ready: true, alert_param: alert_param});
                return false
            }
        }else{
            let alert_param = {title: 'System Error', message: 'Please contact your manager or Compliancy Services customer support on: Tel: 01462 510022 or at regtech@compliancy-services.co.uk.', ok_text: 'Ok', confirm: false,
                       alertHandler: this.alertHandler, stack: {id: 0}}
            this.setState({ ready: true, alert_param: alert_param});
            return
        }
        let members = ComDecom.deCompress(result.result.members, true, 'ID');
        let updated_in = result.result.updated_in
        Store.updateStore('members', members)
        Store.updateStore('updated_in', updated_in)
        Store.updateStore('role', result.role)
        Store.updateStore('contact', result.contact)
        this.setState({ready: true})
    }

	alertHandler = (result, stack) => {
	    this.setState({alert_param: null})
        // window.location = '/select/ServiceBasedLanding';
        Reload.ReloadPage();
	}


	processIndexData = (result) => {
        console.log('companies',result.result)

		let is_admin = Store.getStoreData('is_admin');
        if (is_admin) {
            Store.updateStore('companies', result.result)
            this.setState({ready: true});
        } else {
            this.setState({
                ready: true
            });
        }
	}

	render()
	{
        if(this.state.alert_param !== null) {
          return <AlertBox alertParam = {this.state.alert_param} />
        }
        if (Store.getStoreData('companies') === null && Store.getStoreData('is_admin')) {
          return <div><CSLLoader /></div>
        }
        if (Store.getStoreData('members') === null && !Store.getStoreData('is_admin')) {
          return <div><CSLLoader /></div>
        }

        return(
            <Router>
                <Switch>
                    <Route path="/" exact>
                        <ClientIndex />
                    </Route>
                    <Route path="/:companyId" component={Index} exact />
                </Switch>
            </Router>
        );

    }
}

class App extends React.Component {
    state = { ready: false}

    setComponentState = () => {
        console.log('this.props', this.props)
        let is_debug = false
        let api_url = process.env.REACT_APP_API_URL;
        Store.updateStore('is_debug', is_debug)
        Store.updateStore('api_url', api_url)

        authService.refresh().then(() => {
            this.setState({ready: true})
        })
    }

    componentDidMount = () => {
        this.setComponentState()
    }

    componentDidUpdate = (prevprops) => {
        if(this.props === prevprops) return
        this.setComponentState()
    }

    render() {
        if(!this.state.ready) {
            return <div>Loading...</div>
        }
        // let appContent = <AppContent />
        //
        // return <ErrorBoundary><SiteLayout AppContent={appContent} /></ErrorBoundary>

        return <AppContent />
    }
}
export default App;
