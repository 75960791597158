import React from 'react';
import styled from 'styled-components';

const Pill = styled.div`
	padding: 1px;
	box-sizing: border-box;
	background-color: #FFFFFF;
	margin-left: 0px;
	border: 1px solid #13F7FB;
	border-radius: 12px;
	position: relative;
	height: 24px;
	width: 40px;
	cursor: pointer;
`;

const InnerPill = styled.div`
	box-sizing: border-box;
	margin-left: 0px;
	margin-top: 0px;
	border: 1px solid #F3F7FB;
	border-radius: 10px;
	position: relative;
	height: 20px;
	width: 20px;
	background-color: #046DE4;
	cursor: default;
`;

const InnerPillInactive = styled.div`
	box-sizing: border-box;
	margin-left: 0px;
	margin-top: 0px;
	border: 1px solid #F3F7FB;
	border-radius: 10px;
	position: relative;
	height: 20px;
	width: 20px;
	background-color: #D5E5F7;
	cursor: default;
`;



class CSLCheckBox extends React.Component {
	state = { checked: false }

	constructor(props) {
		super(props);
	}

	componentSetState = () => {
		this.setState({checked: this.props.checked, label: this.props.label})
	}
	componentDidMount = () => {
		this.componentSetState()
	}
	componentDidUpdate = (prev_props) => {
		if(this.props === prev_props)return
		this.componentSetState()	
	}

	doNothing = (evt) => {
		evt.stopPropagation()
		return
	}

	changeChecked = () => {
		if(this.props.checkCallback === null || !('checkCallback' in this.props))return
		let param = 'param' in this.props ? this.props.param : {}
		let checked = !this.state.checked
		console.log('checked', checked)
		this.props.checkCallback(checked, param)
		this.setState({checked})
	}

	render() {
		let float = this.state.checked ? 'right' : 'left'
		return (<div><Pill onClick={this.changeChecked}>
		{
			(() => {
				if(this.state.checked) {
					return <InnerPill style={{float: float}} onClick={this.doNothing} />
				} else {
					return <InnerPillInactive style={{float: float}} onClick={this.doNothing} />
				}
			})()
		}
			
			</Pill></div>)
	}
}

export default CSLCheckBox