import React from 'react';
import styled from 'styled-components';
import {FaTimes} from 'react-icons/fa';
import Store from '../../Common/Store';
import Utils from '../../Common/Utils';
import DatePicker from 'react-date-picker';
// import QuestionAnswerForm from '../QuestionAnswerForm';
import * as Style from '../Common/StyledComponents';
import Dropzone from '../Common/Dropzone.js'
import {FiDownload} from 'react-icons/fi'
import { saveAs } from 'file-saver';
import APICall from '../../Common/APICall';
import AlertBox from '../Common/AlertBox.js'

const MRModalContainer = styled.div`
    display: block;
    position: fixed;
    top: 30vh;
    left: 40vw;
    box-sizing: border-box;
    height: auto;
    z-index: 1001;
    background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 0 20px #a7a6a6;
    padding: 20px;
    width: 50%;
    left: 25%;
    top:40px;
`;
const MRModalHeader = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 20px 20px 10px 20px;
`;
const MRModalHeaderText = styled.div`
    box-sizing: border-box;
    float: left;
    color: #a0a0a0;
`;
const MRModalHeaderCloseBtn = styled.div`
    box-sizing: border-box;
    float: right;
    font-size: 16px;
    color: #a0a0a0;
    cursor: pointer;
`;
const MRModalBody = styled.div`
    display: block;
    box-sizing: border-box;
    padding: 10px 20px 10px 20px;
`;
const MRModalBodyInner = styled.div`
    border-radius: 4px;
    padding: 15px;
    background-color: #ffffff;
`;
const MRModalLabel = styled.label`
    color: #a0a0a0;
    font-weight: 600;
    margin-top: 10px;
`;
const MRModalInput = styled.input`
    height: 40px;
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #979797;
`;
const MRModalBackBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #909090;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    border: 2px solid #dadada;
    margin-left: 5px;
    font-size: 11px;
    margin-right: 10px;
    float: right;
`;

const MRModalFooter = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 20px;
`;
const MRModalCancelBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #909090;
    border: 2px solid #dadada;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    font-size: 11px;
    float: right;
`;
const MRModalNextBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    margin-left: 5px;
    font-size: 11px;
    margin-left: 10px;
    float: right;
`;
const MRModalSelect = styled.select`
    display: block;
    box-sizing: border-box;
    height: 40px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    color: #b5b5b5;
`;
const CSLDateCover = styled.div`
    margin-top: 8px;
    width: 95%;
    border-radius: 5px;
    background-color: #ffffff;
    height: 33px;
    padding-top: 5px;
    padding-left: 10px;
    `;

const ModalNameLabelDiv = styled.div`
    height: auto;
    width: 20%;
    padding-top: 7px;
    font-weight: 600;
    color: #979797;
`;
const ModalNameInputDiv = styled.div`
    height: auto;
    width: 80%;
    padding-top:7px;
`;

class AddGroupingModal extends React.Component
{
    state = {
        grouping_name: '',
        grouping: null,
        alert_param: null,
        ready: false
    };

    constructor(props) {
        super(props)
        this.doGroupingCreate = this.doGroupingCreate.bind(this)
    }

    componentDidMount()
    {
        let grouping_modal_data = Store.getStoreData('grouping_modal_data')
        let grouping = Store.getStoreData('grouping')
        let grouping_tags = Store.getStoreData('grouping_tags')
        let grouping_name = grouping_modal_data !== null ? grouping_modal_data[Object.keys(grouping_modal_data)[0]] : ''

        Utils.log('showAddGroupingModal grouping_modal_data', grouping_modal_data)
        Utils.log('showAddGroupingModal grouping', grouping)
        this.setState({grouping_name: grouping_name, grouping: grouping, grouping_tags:grouping_tags, ready:true})
    }

    closeModal = (event) => {
        event.preventDefault();
        this.props.refreshParentCallback(null);
    }

    handleChange = (evt) => {
        // const value = evt.target.value;
        this.setState({[evt.target.name]: evt.target.value});
    }

    processDivisionCreate = (result) => {
        console.log('result', result)
        alert('Division data saved successfully.')
        this.props.refreshParentCallback()
    }

    saveGroupingProcessorFinal = () => {
        this.props.refreshParentCallback()
    }
    processGroupingCreate = (result) => {
        console.log('result', result)
        let alert_param = {title: 'Success', message: `Grouping saved.`, ok_text: 'Ok', cancel_text: 'No', confirm: false, 
                           alertHandler: this.saveGroupingProcessorFinal, stack:null, body_align: 'center', danger: false}
        this.setState({alert_param: alert_param})
        // alert('Division data saved successfully.')
        // this.props.refreshParentCallback()
    }

    async doGroupingCreate(event){
        event.preventDefault()
        let tags = Store.getStoreData('tags')
        let tags_config = Store.getStoreData('tags_config')
        let index = Store.getStoreData('grouping_modal_data_index')
        index = index === null ? -1 : index
        Utils.log('showAddGroupingModal doGroupingCreate index', index)
        // let grouping_modal_data = Store.getStoreData('grouping_modal_data')
        let parent_company = Store.getStoreData('parent_company')

        Utils.log('doGroupingCreate index', index)

        if(index >= 0){
            tags_config[index][Object.keys(tags_config[index])[0]] = this.state.grouping_name
        } else {
            let elem = {}
            let id = Utils.genKey(5)
            elem[id] = this.state.grouping_name
            tags_config.push(elem)
        }

        Utils.log('showAddGroupingModal tags,tags_config', tags,tags_config)

        let api = new APICall()
        let postData = {command: 'save_tags_config', tags_config: tags_config, tags:tags, parent_company_id: parent_company.ClientNumber }
        api.command(postData, this.processGroupingCreate)
    }


    render()
    {
        console.log(this.state);
        if(!this.state.ready)return <div></div>
        return (
            <div>
                <MRModalContainer style={{top: '200px'}}>
                    <MRModalHeader>
                        <MRModalHeaderText>Add/Edit a Grouping</MRModalHeaderText>
                        <MRModalHeaderCloseBtn onClick={this.closeModal}><FaTimes /></MRModalHeaderCloseBtn>
                        <div style={{clear: "both"}}></div>
                    </MRModalHeader>
                    <MRModalBody>
                        <MRModalBodyInner>
                            <MRModalLabel>Grouping name</MRModalLabel>
                            <MRModalInput type="text" name="grouping_name" onChange={this.handleChange} value={this.state.grouping_name} />
                        </MRModalBodyInner>
                    </MRModalBody>
                    <MRModalFooter>
                        <MRModalNextBtn onClick={this.doGroupingCreate}>Save</MRModalNextBtn><MRModalCancelBtn onClick={this.closeModal}>Cancel</MRModalCancelBtn>
                    </MRModalFooter>
                </MRModalContainer>
                {
                    (() => {
                        if(this.state.alert_param !== null)return <AlertBox alertParam={this.state.alert_param} />
                    })()
                }
            </div>
        );
    }
}
export default AddGroupingModal;