import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store';
import Utils from '../../Common/Utils';
import CSLTable from '../Common/CSLTable';
import AddGroupCompanyModal from './AddGroupCompanyModal';
import AddDivisionModal from './AddDivisionModal';
import AddGroupingTagModal from './AddGroupingTagModal';
import AddGroupingModal from './AddGroupingModal';
import AddRegionModal from './AddRegionModal';
import AddHubModal from './AddHubModal';
import * as Style from '../Common/StyledComponents'
import APICall from '../../Common/APICall';
import UserAssignmentModal from './UserAssignmentModal';
import ModuleConfigPartial from './ModuleConfigPartial'
import AlertBox from '../Common/AlertBox.js'
import CSLLoader from '../Common/CSLLoader'

const SubmitButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #223B5A;
    color: #ffffff;
    width: 160px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    padding-left: 10px;
    margin-left: 5px;
    font-size: 11px;
`;
const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 80px;
    font-size: 13px;
    height: 25px;
    border-radius: 4px;
    margin-left: 5px;
    cursor:pointer;
`;

const MRButtonWide = styled.button`
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 150px;
    font-size: 13px;
    height: 25px;
    border-radius: 4px;
    margin-left: 5px;
    cursor:pointer;
`;

class GCIndex extends React.Component
{
    state = { modal_view: null, view_count: 0, ready: null, alert_param: null }

    constructor(props) {
        super(props)
        this.removeGroupingValueProcessor = this.removeGroupingValueProcessor.bind(this)
        this.removeGCProcessor = this.removeGCProcessor.bind(this)
        this.deleteGroupingProcessor = this.deleteGroupingProcessor.bind(this)

    }

    async refreshData() {
        let parent_company = Store.getStoreData('parent_company')
        console.log('parent_company', parent_company)
        let api = new APICall()
        let postData = {command: 'get_group_companies', parent_id: parent_company.ClientNumber}

        await api.command(postData, this.processGroupCompaniesData)
    }

    componentDidMount()
    {
        this.refreshData()
    }

    async componentDidUpdate(prevProps) {
        if(this.props !== prevProps) {
            await this.refreshData()
        }
    }

    processGroupCompaniesData = (result) => {
        Utils.log('result.result', result.result)
        Utils.log('users',result.result.users)

        Store.updateStore('group_companies', result.result.companies)
        let grouping_tags = {}
        for(let t of result.result.tags_config) {
            grouping_tags[Object.keys(t)[0]] = t[Object.keys(t)[0]]
        }
        Utils.log('grouping_tags', grouping_tags)
        // Store.updateStore('parent_id', parent_id)
        Store.updateStore('users', result.result.users)
        Store.updateStore('contacts', result.result.contacts)
        Store.updateStore('tags', result.result.tags)
        Store.updateStore('grouping_tags', grouping_tags)
        Store.updateStore('tags_config', result.result.tags_config)
        Store.updateStore('users_config', result.result.users_config)
        this.setState({view_count: this.state.view_count + 1, modal_view: null, ready: true, alert_param: null});
    }

    refreshState = () => {
        this.refreshData()
    }

    goToGroupCompanyUsersPage = (index) => (event) => {
        event.preventDefault();
        let user = Store.getStoreData('cur_users')[index]
        Store.updateStore('cur_user', user)
        // Store.updateStore('cur_view', 'user_index')
        this.setState({modal_view:'user_assignment'})
    }

    showAddGroupCompanyModal = (index) => (event) => {
        event.preventDefault()
        if(index >= 0) {
            let gc_modal_data = Store.getStoreData('group_companies')[index]
            Store.updateStore('gc_modal_data', gc_modal_data)
        } else {
            Store.updateStore('gc_modal_data', null)
        }
        this.setState({modal_view:'add_gc_modal'})
    }

    showAddGroupingTagModal = (index, grouping) => (event) => {
        event.preventDefault()
        Utils.log('index, grouping', index, grouping)
        if(index >= 0) {
            let gc_modal_data = Store.getStoreData('tags')[grouping][index]
            Store.updateStore('grouping_tag_modal_data', gc_modal_data)
            Store.updateStore('grouping_tag_modal_data_index', index)
        } else {
            Store.updateStore('grouping_tag_modal_data', null)
        }
        Store.updateStore('grouping', grouping)
        this.setState({modal_view:'add_grouping_tag_modal'})
    }

    showAddDivisionModal = (index) => (event) => {
        event.preventDefault()
        if(index >= 0) {
            let gc_modal_data = Store.getStoreData('tags')['1'][index]
            Store.updateStore('div_modal_data', gc_modal_data)
            Store.updateStore('div_modal_data_index', index)
        } else {
            Store.updateStore('div_modal_data', null)
        }
        this.setState({modal_view:'add_div_modal'})
    }



    showAddRegionModal = (index) => (event) => {
        event.preventDefault()
        if(index >= 0) {
            let gc_modal_data = Store.getStoreData('tags')['2'][index]
            Store.updateStore('region_modal_data', gc_modal_data)
            Store.updateStore('region_modal_data_index', index)
        } else {
            Store.updateStore('region_modal_data', null)
        }
        this.setState({modal_view:'add_region_modal'})
    }

    showAddHubModal = (index) => (event) => {
        event.preventDefault()
        if(index >= 0) {
            let gc_modal_data = Store.getStoreData('tags')['3'][index]
            Store.updateStore('hub_modal_data', gc_modal_data)
            Store.updateStore('hub_modal_data_index', index)
        } else {
            Store.updateStore('hub_modal_data', null)
        }
        this.setState({modal_view:'add_hub_modal'})
    }

    processRegionDeleteCallback = (result, stack) => {
        this.setState({alert_param: null})
    }

    processRegionDelete = (result) => {
        console.log('result', result)
        // alert('Region data saved successfully.')
        let alert_param = {title: 'Success', message: 'Region data saved successfully..', ok_text: 'Ok', confirm: false, 
                           alertHandler: this.processRegionDeleteCallback, stack:null, body_align: 'center', danger: false}
        this.setState({alert_param: alert_param})
        // this.refreshState()
    }

    processDivisionTagsData = () => {
        let tabledata = Store.getStoreData('tags')['1'];
        console.log('divs tabledata', tabledata)
        let ret = {data: [], columns: []};
        let i = 0;
        ret.columns =[  {Header: '#', accessor: 'index', width: 80, headerStyle: {textAlign: 'left'}}, 
                        {Header: 'Name', accessor: 'name', width: 600, headerStyle: {textAlign: 'left', minWidth: 500}}, 
                        {'Header' : 'Action', Cell: row => (
                           <div>
                               <MRButton onClick={this.showAddDivisionModal(row.index)}>Edit</MRButton>
                               <MRButton onClick={() => this.deleteGroupingValue(row.index, '1')}>Delete</MRButton>
                           </div>
                        ), headerStyle: {textAlign: 'center'}}];
        i=0

        for(let d of tabledata) {
            i++
            let elem = {
                id: d.id,
                index: i,
                name: d.name
            }
            ret.data.push(elem);
        }
        return ret
    }

    processHubTagsData = () => {
        let tabledata = Store.getStoreData('tags')['3'];
        let ret = {data: [], columns: []};
        let i = 0;
        ret.columns =[  {Header: '#', accessor: 'index', width: 80, headerStyle: {textAlign: 'left'}}, 
                        {Header: 'Name', accessor: 'name', width: 600, headerStyle: {textAlign: 'left', minWidth: 500}}, 
                        {'Header' : 'Action', Cell: row => (
                           <div>
                               <MRButton onClick={this.showAddHubModal(row.index)}>Edit</MRButton>
                               <MRButton onClick={() => this.deleteGroupingValue(row.index, '3')}>Delete</MRButton>
                           </div>
                        ), headerStyle: {textAlign: 'center'}}];
        i=0

        for(let d of tabledata) {
            i++
            let elem = {
                id: d.id,
                index: i,
                name: d.name
            }
            ret.data.push(elem);
        }
        return ret
    }

    removeGroupingValueCallback = (result, stack) => {
        // window.location.reload(true)
        this.refreshState()
    }

    removeGroupingValueAPICall = (result) => {
        // 
        let del_tag_name = Store.getStoreData('del_tag_name')
        let del_tag_value = Store.getStoreData('del_tag_value')
        console.log('del_tag_name, del_tag_value', del_tag_name, del_tag_value)
        let alert_param = {title: 'Success', message: `The <strong>${del_tag_name}</strong> "${del_tag_value}" has been removed successfully.`, ok_text: 'Ok', cancel_text: 'No', confirm: false, 
                           alertHandler: this.removeGroupingValueCallback, stack: {}, body_align: 'left', danger: false}
        this.setState({alert_param})
    }

    async removeGroupingValueProcessor(result, stack){
        if(!result){
            this.setState({alert_param: null})
            return;
        }
        let tags = stack.tags;
        let tag_id = stack.tag_id;
        let index = stack.index;
        let parent_company = Store.getStoreData('parent_company')
        tags[tag_id].splice(index, 1)
        Store.updateStore('tags', tags)
        let api = new APICall()
        let postData = {command: 'save_tags', tags: tags, parent_company_id: parent_company.ClientNumber }
        console.log('postData', postData)
        await api.command(postData, this.removeGroupingValueAPICall)
        // this.setState({alert_param: null})
    }

    deleteGroupingValue = (index, tag_id) => {
        let gcs = Store.getStoreData('group_companies')
        let tags = Store.getStoreData('tags') 
        let tags_config = Store.getStoreData('tags_config') 
        let users_config = Store.getStoreData('users_config')
        let del_tag_name = ''; //tags_config[tag_id][index]
        for(let t of tags_config) {
            if(Object.keys(t)[0] === tag_id)del_tag_name = t[tag_id]
        }
        let companies_count = 0;
        let users_count = 0;
        let del_tag_id = tags[tag_id][index].id
        let del_tag_value = tags[tag_id][index].name
        for(let gc of gcs) {
            if(gc.tags_config[tag_id] === del_tag_id)companies_count++
        }

        for(let u_id in users_config) {
            if(tag_id in users_config[u_id].tags && del_tag_id in users_config[u_id].tags[tag_id])users_count++
        }

        Store.updateStore('del_tag_name', del_tag_name)
        Store.updateStore('del_tag_value', del_tag_value)

        // console.log('del_tag_id, companies_count, users_count', del_tag_id, companies_count, users_count)
        // console.log('del_tag_name gcs users_config, tags_config, tags', del_tag_name, gcs, users_config, tags_config, tags)
        let alert_param = {title: 'Warning', message: `Are you sure you want to remove this ${del_tag_name}?`, ok_text: 'Yes', cancel_text: 'No', confirm: true, 
                           alertHandler: this.removeGroupingValueProcessor, stack: {index: index, tags: tags, tag_id: tag_id}, body_align: 'left', danger: true}

        if(users_count + companies_count > 0) {
            alert_param.title = 'ERROR!';
            alert_param.ok_text = 'Ok'
            alert_param.confirm = false
            alert_param.alertHandler = this.cancelRemoveProcessor
            alert_param.message = '';
            alert_param.danger = false
            let company_label = companies_count === 1 ? 'Company' : 'Companies'
            let user_label = users_count === 1 ? 'User' : 'Users'
            let company_verb_label = companies_count === 1 ? 'is' : 'are'
            let user_verb_label = users_count === 1 ? 'is' : 'are'
            if(companies_count > 0)alert_param.message += `<p>Cannot remove this ${del_tag_name} as there ${company_verb_label} ${companies_count} Group ${company_label} assigned to it.</p>`
            if(users_count > 0)alert_param.message += `<p>Cannot remove this ${del_tag_name} as there ${user_verb_label} ${users_count} ${user_label} assigned to it.</p>`
        }  
        this.setState({alert_param: alert_param})
    }


    processGroupingTagsData = (params) => {
        let grouping = params.grouping
        Utils.log('processGroupingTagsData params', params)
        let tags = Store.getStoreData('tags')
        let tabledata = grouping in tags ? Store.getStoreData('tags')[grouping] : [];
        Utils.log('tabledata', tabledata)
        // tabledata = tabledata !== null ? tabledata : []
        Utils.log('processGroupingTagsData tabledata', tabledata)
        let ret = {data: [], columns: []};
        let i = 0;
        ret.columns =[  {Header: '#', accessor: 'index', width: 80, headerStyle: {textAlign: 'left'}}, 
                        {Header: 'Name', accessor: 'name', width: 600, headerStyle: {textAlign: 'left', minWidth: 500}}, 
                        {'Header' : 'Action', Cell: row => (
                           <div>
                               <MRButton onClick={this.showAddGroupingTagModal(row.index, grouping)}>Edit</MRButton>
                               <MRButton onClick={() => this.deleteGroupingValue(row.index, grouping)}>Delete</MRButton>
                           </div>
                        ), headerStyle: {textAlign: 'center'}}];
        i=0

        for(let d of tabledata) {
            i++
            let elem = {
                id: d.id,
                index: i,
                name: d.name
            }
            ret.data.push(elem);
        }
        return ret        
    }

    processRegionTagsData = () => {
        let tabledata = Store.getStoreData('tags')['2'];
        let ret = {data: [], columns: []};
        let i = 0;
        ret.columns =[  {Header: '#', accessor: 'index', width: 80, headerStyle: {textAlign: 'left'}}, 
                        {Header: 'Name', accessor: 'name', width: 600, headerStyle: {textAlign: 'left', minWidth: 500}}, 
                        {'Header' : 'Action', Cell: row => (
                           <div>
                               <MRButton onClick={this.showAddRegionModal(row.index)}>Edit</MRButton>
                               <MRButton onClick={() => this.deleteGroupingValue(row.index, '2')}>Delete</MRButton>
                           </div>
                        ), headerStyle: {textAlign: 'center'}}];
        i=0

        for(let d of tabledata) {
            i++
            let elem = {
                id: d.id,
                index: i,
                name: d.name
            }
            ret.data.push(elem);
        }
        return ret
    }

    processUsersData = () => {
        let tabledata = Store.getStoreData('contacts')
        let users = Store.getStoreData('users')

        let ret = {data: [], columns: []}
        let i = 0;
        ret.columns =[  {Header: '#', accessor: 'index', width: 110, headerStyle: {textAlign: 'left'}}, 
                        {Header: 'Name', accessor: 'name', width: 300, headerStyle: {textAlign: 'left', minWidth: 180}}, 
                        {Header: 'Role', accessor: 'role', width: 300, headerStyle: {textAlign: 'left', minWidth: 180}}, 
                        {'Header' : 'Action', Cell: row => (
                           <div>
                               <MRButton style={{width: '180px'}} onClick={this.goToGroupCompanyUsersPage(row.index)}>Assignments</MRButton>
                           </div>
                        ), headerStyle: {textAlign: 'center'}}];
        for(let d of tabledata) {
            // console.log('data element->', d);
            // if(users[d.ID] !== 'team') {
                i++;
                let role = 'Team Member'
                switch(users[d.ID]) {
                    case 'manager' : role = 'Manager'; break;
                    case 'admin_manager' : role = 'Admin Manager'; break;
                    case 'file_checker' : role = 'File Checker'; break;
                    default: role = 'Team Member'
                }
                let elem = {
                    id: d.ID,
                    index : i,
                    name : d.ContactName,
                    role: role
                };
                ret.data.push(elem);
            // }
        }
        Store.updateStore('cur_users', ret.data)
        return ret
    }

    removeGCProcessorFinal = (result, stack) => {
        this.refreshState()
    }

    removeGCProcessorCallback = (result) => {
        console.log('result', result)
        
        let alert_param = {title: 'Success', message: 'Group company removed successfully.', ok_text: 'Ok', cancel_text: 'No', confirm: false, 
                           alertHandler: this.removeGCProcessorFinal, stack:null, body_align: 'center', danger: false}
        this.setState({alert_param: alert_param})
    }

    async removeGCProcessor(result, stack){
        if(!result) {
            this.setState({alert_param: null})
            return
        } else {
            let api = new APICall()
            let postData = {command: 'remove_gc', parent_id: stack.gc.parent_id, gc_id: stack.gc.id}
            console.log('postData', postData)

            await api.command(postData, this.removeGCProcessorCallback)
        }
    }

    cancelRemoveProcessor = (result, stack) => {
        this.refreshState()
    }

    deleteGC = (gc_id) => {
        let gc = Store.getStoreData('group_companies')[gc_id] 
        let tags = Store.getStoreData('tags') 
        let tags_config = Store.getStoreData('tags_config') 
        let users_config = Store.getStoreData('users_config') 
        /*let alert_param = {title: 'Warning', message: 'Are you sure you want to remove this Group Company?', ok_text: 'Yes', cancel_text: 'No', confirm: true, 
                           alertHandler: this.removeGCProcessor, stack: {gc_id: gc_id, gc: gc}, body_align: 'left', danger: true}*/

        let alert_param = {title: 'Confirm', message: `Deleting a Group Company may cause data issues where Users and Module Entities like File Checks, Compliance Advice, etc. are associated with this Group Company. Please delete this Group Company only if you know it has not been used or consider renaming it instead.`, ok_text: 'Confirm', cancel_text: 'Cancel', confirm: true, 
                           alertHandler: this.removeGCProcessor, stack: {gc_id: gc_id, gc: gc}, body_align: 'center', danger: false}

        // console.log('gc', gc)
        // console.log('tags', tags)
        // console.log('tags_config', tags_config)
        // console.log('users_config', users_config)
        for(let tc of tags_config) {
            let tag_id = Object.keys(tc)[0]
            if(!(tag_id in tags))tags[tag_id]=[]
        }
        let all_tags = []
        for(let tg of tags_config) {
            let tag_id = Object.keys(tg)[0]
            let tag_values = {}
            for(let tt of tags[tag_id]) {
                tag_values[tt.id] = tt.name
            }
            all_tags.push({tag_id: tag_id, tag_name: tg[tag_id], tag_values: tag_values})
        }
        // console.log('all_tags', all_tags)
        let tags_assoc = []
        for(let at of all_tags) {
            if((at.tag_id in gc.tags_config) && gc.tags_config[at.tag_id] !== '0') {
                tags_assoc.push({name: at.tag_name, value: at.tag_values[gc.tags_config[at.tag_id]]})
            }
        }
        // console.log('tags_assoc', tags_assoc)
        let users_count = 0
        for(let u_id in users_config) {
            if(gc.id.toString() in users_config[u_id].companies) {
                // console.log('gc_id users_config[u_id].companies', gc.id, users_config[u_id].companies)
                users_count++
            }
        }
        // console.log('users_count', users_count)
        if(users_count + tags_assoc.length > 0) {
            alert_param.title = 'ERROR!';
            alert_param.ok_text = 'Ok'
            alert_param.confirm = false
            alert_param.alertHandler = this.cancelRemoveProcessor
            alert_param.message = '';
            alert_param.danger = false
            if(tags_assoc.length > 0) {
                alert_param.message += '<p>Cannot delete a Group company that is associated with one or more Grouping Tag(s). This group company is associated with the following grouping tags:</p><ul>'
                for(let tgs of tags_assoc) {
                    alert_param.message += `<li><strong>${tgs.name}:</strong> ${tgs.value}</li>`
                }
                alert_param.message += '</ul>'
            }
            if(users_count > 0) {
                alert_param.message += `<p>Cannot delete a Group Company that has members assigned to it. This company has <strong>${users_count} members</strong> assigned directly to it.</p>`
            }
            alert_param.message += '<p>Please remove the above mentioned associations before trying again.</p>'
        }
        this.setState({alert_param})
    } 

    showAddGroupingModal = (index) => (event) => {
        event.preventDefault()
        let gc_modal_data = index >= 0 ? Store.getStoreData('tags_config')[index] : null
        Store.updateStore('grouping_modal_data', gc_modal_data)
        Store.updateStore('grouping_modal_data_index', index)
        
        // if(index >= 0) {
        //     gc_modal_data = Store.getStoreData('tags_config')[index]
        //     Store.updateStore('grouping_modal_data', gc_modal_data)
        //     Store.updateStore('grouping_modal_data_index', index)
        // } else {
        //     Store.updateStore('grouping_modal_data', null)
        //     Store.updateStore('grouping_modal_data_index', index)
        // }
        Utils.log('showAddGroupingModal index, gc_modal_data', index, gc_modal_data)
        this.setState({modal_view:'add_grouping_modal'})

    }

    deleteGroupingProcessorFinal = (result) => {
        let grouping_name = Store.getStoreData('grouping_name')
        let alert_param = {title: 'Success', message: `Grouping <strong>"${grouping_name}"</strong> deleted.`, ok_text: 'Ok', cancel_text: 'No', confirm: false, 
                           alertHandler: this.refreshState, stack: {}, body_align: 'left', danger: false}
        this.setState({alert_param})
    }

    async deleteGroupingProcessor(result, stack) {
        if(!result){this.deleteGroupingCancelProcessor(); return;}
        Utils.log('stack', stack)
        let index = 'index' in stack ? stack.index : -1
        if(index < 0)return;
        let tags = Store.getStoreData('tags')
        let tags_config = Store.getStoreData('tags_config')

        let tag_id = Object.keys(tags_config[index])[0]
        let grouping_name = tags_config[index][tag_id]
        Store.updateStore('grouping_name',grouping_name)
        tags_config.splice(index, 1)
        if(tag_id in tags)delete tags[tag_id]
        Utils.log('stack tag_id, tags, tags_config', tag_id, tags, tags_config)
        let parent_company = Store.getStoreData('parent_company')
        let api = new APICall()
        let postData = {command: 'save_tags_config', tags_config: tags_config, tags:tags, parent_company_id: parent_company.ClientNumber }
        await api.command(postData, this.deleteGroupingProcessorFinal)
    }

    deleteGroupingCancelProcessor = (result, stack) => {
        this.setState({alert_param: null})
    }

    deleteGrouping = (index) => {
        let tags = Store.getStoreData('tags')
        let tags_config = Store.getStoreData('tags_config')
        let grouping = Object.keys(tags_config[index])[0]
        let alert_param = {title: 'Warning', message: 'Are you sure you want to remove this Grouping?', ok_text: 'Yes', cancel_text: 'No', confirm: true, 
                           alertHandler: this.deleteGroupingProcessor, stack: {index: index}, body_align: 'left', danger: true}
        if(grouping in tags && tags[grouping].length > 0) {
            alert_param = {title: 'ERROR', message: 'You cannot remove a Grouping that has names associated with it.', ok_text: 'OK', cancel_text: 'No', confirm: false, 
                           alertHandler: this.deleteGroupingCancelProcessor, stack: {index: index}, body_align: 'left', danger: true}
        }
        this.setState({alert_param})
    }

    processGroupingsData = () => {
        let ret = {data: [], columns: []};
        let groupings = Store.getStoreData('tags_config');
        let tabledata = []
        for(let g of groupings) {
            let id = Object.keys(g)[0]
            tabledata.push({id:id, name:g[id] })
        }
        Utils.log('tabledata', tabledata)
        let tags = Store.getStoreData('tags');
        let tags_config = Store.getStoreData('tags_config');
        ret.columns =[  {Header: '#', accessor: 'index', width: 80, headerStyle: {textAlign: 'left'}}, 
                        {Header: 'Name', accessor: 'name', headerStyle: {textAlign: 'left', minWidth: 400}},
                        {'Header' : 'Action', width: 200, Cell: row => (
                           <div>
                               <MRButton onClick={this.showAddGroupingModal(row.index)}>Edit</MRButton>
                               <MRButton onClick={() => this.deleteGrouping(row.index)}>Delete</MRButton>
                           </div>
                        ), headerStyle: {textAlign: 'center'}}];

        // Utils.log('tabledata, tags_config, tags', tabledata, tags_config, tags)
        let i = 0;
        for(let d of tabledata) {
            i++;
            let elem = {
                id: d.id,
                index : i,
                name : d.name
            };
            ret.data.push(elem);
        }
        return ret        
    }


    processCompaniesData = () => {
        let tabledata = Store.getStoreData('group_companies');
        // let tags = Store.getStoreData('tags');
        let ret = {data: [], columns: []};
        let i = 0;
        ret.columns =[  {Header: '#', accessor: 'index', width: 80, headerStyle: {textAlign: 'left'}}, 
                        {Header: 'Name', accessor: 'name', headerStyle: {textAlign: 'left', minWidth: 400}},
                        {'Header' : 'Action', width: 200, Cell: row => (
                           <div>
                               <MRButton onClick={this.showAddGroupCompanyModal(row.index)}>Edit</MRButton>
                               <MRButton onClick={() => this.deleteGC(row.index)}>Delete</MRButton>
                           </div>
                        ), headerStyle: {textAlign: 'center'}}];

        for(let d of tabledata) {
            // console.log('data element->', d);
            i++;
            let elem = {
                id: d.id,
                index : i,
                name : d.company_name
            };
            ret.data.push(elem);
        }
        // console.log('ret', ret)
        return ret;
    }

    closeModal = () => {
        this.setState({modal_view: null});
    }

    render()
    {
        if(this.state.ready === null) {
            return <div>Loading...</div>
        }
        let grouping_tags = Store.getStoreData('grouping_tags')
        return (
            <div>
                <ModuleConfigPartial />
                <div style={{clear: "both", height: '3px'}}></div>
                <CSLTable add={false} tableRows={10} processData={this.processUsersData} headerText={ Store.getStoreData('parent_company').CompanyName + ': Users'} refreshCallback={this.refreshState} />
                <div style={{clear: "both", height: '3px'}}></div>
                <SubmitButton onClick={this.showAddGroupCompanyModal(-1)} refreshParent={this.refreshState}>Add Group Company</SubmitButton>
                <div style={{clear: "both", height: '3px'}}></div>
                <CSLTable add={false} tableRows={5} processData={this.processCompaniesData} headerText={ Store.getStoreData('parent_company').CompanyName + ': Group Companies'} refreshCallback={this.refreshState} />
                <div style={{clear: "both", height: '3px'}}></div>

                <div style={{clear: "both", height: '3px'}}></div>
                <SubmitButton onClick={this.showAddGroupingModal(-1)} refreshParent={this.refreshState}>Add Grouping</SubmitButton>
                <div style={{clear: "both", height: '3px'}}></div>
                <CSLTable add={false} tableRows={5} processData={this.processGroupingsData} headerText={ Store.getStoreData('parent_company').CompanyName + ': Groupings'} refreshCallback={this.refreshState} />
                <div style={{clear: "both", height: '3px'}}></div>
                {
                    Object.keys(grouping_tags).map((t, i) => {
                        return (
                            <div key={i}>
                                <SubmitButton onClick={this.showAddGroupingTagModal(-1, t)} refreshParent={this.refreshState}>Add {grouping_tags[t]}</SubmitButton>
                                <div style={{clear: "both", height: '3px'}}></div>
                                <CSLTable 
                                    add={false} 
                                    tableRows={5} 
                                    processDataParams={{grouping: t}}
                                    processData={this.processGroupingTagsData} 
                                    headerText={ Store.getStoreData('parent_company').CompanyName + ': ' + grouping_tags[t]} 
                                    refreshCallback={this.refreshState} 
                                />
                                <div style={{clear: "both", height: '3px'}}></div>
                            </div>
                        )
                    })
                }
                {
                    (() => {
                        if(this.state.modal_view !== null) {
                            switch(this.state.modal_view) {
                                case 'add_gc_modal': return <div><Style.InactiveOverlay /><AddGroupCompanyModal closeModal={this.closeModal} viewCount={this.state.view_count} refreshParentCallback={this.refreshState} /></div>; break;
                                case 'add_grouping_modal': return <div><Style.InactiveOverlay /><AddGroupingModal closeModal={this.closeModal} viewCount={this.state.view_count} refreshParentCallback={this.refreshState} /></div>; break;
                                case 'add_grouping_tag_modal': return <div><Style.InactiveOverlay /><AddGroupingTagModal closeModal={this.closeModal} viewCount={this.state.view_count} refreshParentCallback={this.refreshState} /></div>; break;
                                case 'user_assignment': return <div><Style.InactiveOverlay /><UserAssignmentModal closeModal={this.closeModal} viewCount={this.state.view_count} refreshParentCallback={this.refreshState} /></div>; break;
                            }
                        }
                    })()
                }
                <AlertBox alertParam = {this.state.alert_param} />
                <CSLLoader />
            </div>
        );
    }
}

export default GCIndex;
