import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store.js';
import Utils from '../../Common/Utils.js';
import APICall from '../../Common/APICall.js';
import * as Style from '../Common/StyledComponents'
import CSLTableAlt from '../Common/CSLTableAlt'
import CSLCheckBox from '../Common/CSLCheckBox'
import AlertBox from '../Common/AlertBox'
import ComDecom from '../../Common/ComDecom.js';

import {FaPlus, FaPencilAlt, FaTimes, FaEllipsisV, FaUser} from 'react-icons/fa';

const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #046DE4;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 100px;
    font-size: 14px;
    height: 35px;
    border-radius: 4px;
    margin-left: 5px;
    margin-top: 5px;
    cursor: pointer;
`;
const MenuBar = styled.div`
    height: 35px;
    width: calc(100% - 10px);
    background-color: #F9FAFB;
    position: relative;
    color: #9CA4B2;
    border-bottom-style: solid;
    border-bottom-color:  #FEEBF4;
    border-bottom-width: 2px;
    padding-top: 3px;
    padding-left: 0px;
    margin-top: 5px;
`;
const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 100vh;
  width: calc(100vw - 330px);
  background-color: #ffffff;
  position: fixed;
  left: 300px;
  top: 80px;
  z-index: 1000;
  opacity: 0.8;
`;

const ModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: calc(100vh - 160px);
    width: calc(100vw - 340px);
    position: fixed;
    top: 150px;
    left: 310px;
    z-index: 1001;
    background-color: #F3F7FB;
    box-shadow: 0 0 5px #a7a6a6;
    margin-bottom: 10px;
    border-radius: 5px;
    background-color: white;
`;


const ModalHeader = styled.div`
    background-color: white;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 14px;
    font-weight: 700;
    height: 60px;
    color: #046DE4;
    width: calc(100% - 12px);
`;
const ModalBody = styled.div`
    position: relative;
    display: block;
    box-sizing: border-box;
    background-color: #F3F7FB;
    padding: 10px;
    border-radius: 4px;
    height: calc(100vh - 280px);
    overflow-y: auto;
`;
const ModalBodyInner = styled.div`
    border-radius: 4px;
    padding: 15px;
    background-color: #ffffff;
`;
const ModalLabel = styled.label`
    color: #242424;
    font-weight: 600;
    margin-top: 10px;
    font-size: 16px;
    padding-bottom: 5px;
`;
const ModalInput = styled.input`
    height: 40px;
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #979797;
`;

const ModalHeaderText = styled.div`
    float: left;
    font-weight: bold;
    box-sizing: border-box;
    font-size: 24px;
    margin-top: 16px;
    padding-left: 25px;
    background-color: white;
    color: #046DE4;
    font-family: 'Montserrat', sans-serif;
`;

const ModalHeaderCloseBtn = styled.div`
    float: right;
    box-sizing: border-box;
    margin-top: 16px;
    margin-right: 20px;
    color: #949494;
    font-size: 16px;
    cursor: pointer;
`;

class AssignUsersDialog extends React.Component {
    state = {users_config: null, users_config_original: null, alert_param: null, companies_lookup: null, companies: null, companies_loading: false, users_config_loading: false}

    processAPICall = (result) => {
        
        let users_config = result.result.users_config
        Utils.log('loading users_config', users_config)
        Store.updateStore('users_config', users_config)
        this.setState({users_config,users_config_original:users_config, users_config_loading: false})
    }

    processAPICallCompanies = (result) => {
        let companies = ComDecom.deCompress(result.result.companies, false, 'id')
        let companies_lookup = {}
        for(let c of companies) {
            companies_lookup[c.id] = c
        }
        Store.updateStore('companies', companies)
        Store.updateStore('companies_lookup', companies_lookup)
        this.setState({companies, companies_lookup, companies_loading: false})
    }

    setComponentState = () => {
        let users_config = Store.getStoreData('users_config')
        let companies_lookup = Store.getStoreData('companies_lookup')
        Utils.log('AssignUsersDialog setComponentState users_config', users_config)
        if(companies_lookup === null) {
            let api = new APICall()
            let post_data = {command: 'client_get_group_companies'}
            api.command(post_data, this.processAPICallCompanies);
            this.setState({companies_loading: true})
            if(users_config === null) {
                post_data = {command: 'client_get_users_config'}
                api.command(post_data, this.processAPICall)
            }
        } else {
            this.setState({users_config,users_config_original:users_config,  companies_loading: false, users_config_loading: false})
        }
    }

    setComponentState_obsulate = () => {
        let users_config = Store.getStoreData('users_config')
        let companies_lookup = Store.getStoreData('companies_lookup')
        Utils.log('AssignUsersDialog setComponentState users_config', users_config)
        if(users_config === null) {
            let api = new APICall()
            let post_data = {command: 'client_get_users_config'}
            api.command(post_data, this.processAPICall)
            
            if(companies_lookup === null) {
                post_data = {command: 'client_get_group_companies'}
                api.command(post_data, this.processAPICallCompanies)
                this.setState({companies_loading: true})
            }
        } else {
            this.setState({users_config, companies_loading: false, users_config_loading: false})
        }
    }

    componentDidMount = () => {
        this.setState({companies_loading: true, users_config_loading: true})
        this.setComponentState()
    }

    componentDidUpdate = (prevprops) => {
        if(this.props === prevprops)return
        this.setState({companies_loading: true, users_config_loading: true})
        this.setComponentState()
    }

    handleChange = (evt) => {
        this.setState({[evt.target.name]: evt.target.value});
    }


    assignGrouping = (check, param) => {
        let grouping = param.grouping
        let id = param.id

        let users_config = this.state.users_config
        if(check){
            if(!(this.props.userId in users_config))users_config[this.props.userId] = {tags: {}, companies: {}}
            if(!(grouping in users_config[this.props.userId].tags))users_config[this.props.userId].tags[grouping]={}
            users_config[this.props.userId].tags[grouping][id] = 1
        } else {
            delete users_config[this.props.userId].tags[grouping][id]
        }

        this.setState({users_config})
    }

    assignCompany = (check, param) => {
        let id = param.id
        let user_config = param.user_config
        Utils.log('assignCompany user_config, check', user_config, check)
        if(check) {
            if(!(id in user_config.companies))user_config.companies[id] = 1
        } else {
            if(id in user_config.companies)delete user_config.companies[id]
        }
        let users_config = this.state.users_config
        users_config[this.props.userId] = user_config
        this.setState({users_config:users_config})
    }

    processCompaniesData = () => {
        // let cur_user = this.state.cur_user !== null ? this.state.cur_user : {}
        let companies = Store.getStoreData('companies')
        companies = companies !== null ? companies : []
        let groupings = Store.getStoreData('groupings')
        let new_users_config = {companies: {}, tags: {}}
        for(let g in groupings) {
            new_users_config.tags[g] = {}
        }
        let ret = {data: [], columns: []};
        let user_config = this.state.users_config !== null && this.props.userId in this.state.users_config ? this.state.users_config[this.props.userId] : new_users_config
        let config_original = Store.getStoreData('users_config')
        let users_config_original = config_original !== null && this.props.userId in config_original ? config_original[this.props.userId] : new_users_config
        

        Utils.log('assignCompany top user_config, this.state.users_config, this.props.userId', users_config_original)
        ret.columns =[  {Header: '#', accessor: 'index', width: '80', headerStyle: {textAlign: 'left'}}, 
                        {Header: 'Company Name', accessor: 'name', width: 'calc(100vw - 600px)', headerStyle: {textAlign: 'left', minWidth: 180, width:'calc(100vw - 600px)'},
                            Cell: row => (
                                    <>{
                                        (() => {
                                            return (<div>{row.original.name} <span style={{fontSize: "12px",paddingLeft: "30px",color: "#a8a8a8",fontStyle: "italic"}}>{row.original.msg}</span></div>)
                                        })()
                                    }
                                    </>
                                )
                        }, 
                        {'Header' : 'Assign/Deassign', width:180, Cell: row => (
                           <div>
                                {
                                    (() => {
                                        return (<CSLCheckBox 
                                                    param={{id: row.original.id, user_config: user_config}} 
                                                    checkCallback={this.assignCompany}
                                                    checked={row.original.is_assigned} 
                                                    style={{borderColor: ''}} />)

                                    })()

                                        
                                }
                           </div>
                        ), headerStyle: {textAlign: 'left', width: 180}}];
        let i = 0
        for(let d of companies) {
            i++
            let msg = d.is_active === 0 ? '  - This group company has been made inactive' : ''
            let elem = {
                id: d.id,
                index: i,
                name: d.company_name,
                msg: msg,
                is_assigned: d.id in user_config.companies ? true : false
            }
            Utils.log('assignCompany elem, user_config', elem, users_config_original)
            if(d.id in users_config_original.companies || d.is_active === 1){
                ret.data.push(elem)
            }
            // ret.data.push(elem)
            
        }
        console.log('data', ret.data)
        ret.data.sort(this.nameCompare)
        return ret
    }

    nameCompare = (a,b) => {
        return a.name.localeCompare(b.name)
    }

    processGroupingsData = (param) => {
        // Utils.log('processGroupingsData param', param)
        let grouping = param.grouping
        let tags = Store.getStoreData('tags')
        let groupings = Store.getStoreData('groupings')
        let grouping_name = groupings[grouping]
        let grouping_tags = tags[grouping]
        let assigns = this.props.userId in this.state.users_config && grouping in this.state.users_config[this.props.userId].tags ? this.state.users_config[this.props.userId].tags[grouping] : {}
        let ret = {data: [], columns: []};

        ret.columns =[  {Header: '#', accessor: 'index', width: 120, headerStyle: {textAlign: 'left', width: 120}}, 
                        {Header: grouping_name + ' Name', accessor: 'name', headerStyle: {textAlign: 'left'}}, 
                        {'Header' : 'Assign/Deassign', Cell: row => (
                           <div>
                                {
                                    (() => {
                                        return (<CSLCheckBox 
                                                    param={{grouping: grouping, id: row.original.id}} 
                                                    checkCallback={this.assignGrouping}
                                                    checked={row.original.is_assigned} 
                                                    style={{borderColor: ''}} />)
                                    })()                                       
                                }
                           </div>
                        ), headerStyle: {textAlign: 'left', maxWidth: 180, width: 180}, width: 180}];

        let k = 0
        for(let r of grouping_tags) {
            k++
            let elem = {index: k, name:r.name, id: r.id, is_assigned: r.id in assigns }
            ret.data.push(elem)
        }
        ret.data.sort(this.nameCompare)
        return ret
    }

    refreshState = () => {

    }

    processSaveUsersConfigAPICall = (result) => {
        Utils.log('result save', result)
        let alert_param = {title: 'Success', message: `User assignment saved.`, ok_text: 'Ok', cancel_text: 'No', confirm: false, 
                           alertHandler: this.props.closeDialog, stack: {}, body_align: 'left', danger: false}
        this.setState({alert_param: alert_param}) 
    }

    saveUsersConfig = () => {
        let users_config = this.state.users_config
        Utils.log('result save users_config', users_config)
        Store.updateStore('users_config', users_config)
        let api = new APICall()
        let post_data = {command: 'client_save_users_config', users_config: users_config}
        Utils.log('before save post_data', post_data)
        api.command(post_data, this.processSaveUsersConfigAPICall)
    }

    render() {
        if(this.state.users_config === null)return <div></div>

        let tags = Store.getStoreData('tags')
        let groupings = Store.getStoreData('groupings')
        let companies_lookup = Store.getStoreData('companies_lookup')


        let contacts = Store.getStoreData('members')
        let contact_name = contacts[this.props.userId].ContactName
        Utils.log('AssignUsersDialog this.state', this.state)
        let company_rows = companies_lookup !== null ? Object.keys(companies_lookup).length + 3 : 3; //'companies' in this.state.users_config ? Object.keys(this.state.users_config.companies).length + 3 : 3
        return (<div>
              <ModalContainer>
                <ModalHeader>
                    <ModalHeaderText>User Assignments</ModalHeaderText>
                    <Style.ModalHeaderCloseBtn onClick={() => this.props.closeDialog()}><FaTimes /></Style.ModalHeaderCloseBtn>
                </ModalHeader>
                <ModalBody>
                    <ModalBodyInner>
                    {
                        Object.keys(groupings).map((k,i) => {
                            // console.log('groupings k', k)
                            let row_num = tags[k].length + 3
                            return (<CSLTableAlt key={i} add={false} tableRows={row_num} processData={this.processGroupingsData} 
                                processDataParams={{grouping: k}}
                                headerText={`User Assignment to ${groupings[k]}(s) for : ` + contact_name }
                                refreshCallback={this.refreshState} />)
                        })
                    }
                    <CSLTableAlt add={false} tableRows={company_rows} processData={this.processCompaniesData} 
                                processDataParams={null}
                                headerText={`User Assignment to Companies for : ` + contact_name }
                                refreshCallback={this.refreshState} />
                    </ModalBodyInner>
                </ModalBody>
                <ModalHeader>
                    <MRButton style={{float: 'right'}} onClick={() => this.saveUsersConfig()}>Save</MRButton>
                    <MRButton style={{float: 'right', backgroundColor: 'white', color: '#046DE4'}} onClick={() => this.props.closeDialog()}>Cancel</MRButton>
                </ModalHeader>
              </ModalContainer>
              <AlertBox alertParam={this.state.alert_param} />
              
            </div>)
    }
}

export default AssignUsersDialog;

// <CSLLoader show={this.state.companies_loading || this.state.users_config_loading} />
