import React from 'react';
import styled from 'styled-components';
import {FaTimes} from 'react-icons/fa';
import Store from '../../Common/Store';
import Utils from '../../Common/Utils';
import DatePicker from 'react-date-picker';
// import QuestionAnswerForm from '../QuestionAnswerForm';
import * as Style from '../Common/StyledComponents';
import Dropzone from '../Common/Dropzone.js'
import {FiDownload} from 'react-icons/fi'
import { saveAs } from 'file-saver';
import APICall from '../../Common/APICall';
import CSLTable from '../Common/CSLTable';
import AlertBox from '../Common/AlertBox.js'

const MRModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    position: absolute;
    z-index: 1001;
    background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 0 20px #a7a6a6;
    padding: 20px;
    width: 90%;
    left: 5%;
    top:40px;
`;
const MRModalHeader = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 20px 20px 10px 20px;
`;
const MRModalHeaderText = styled.div`
    box-sizing: border-box;
    float: left;
    color: #a0a0a0;
`;
const MRModalHeaderCloseBtn = styled.div`
    box-sizing: border-box;
    float: right;
    font-size: 16px;
    color: #a0a0a0;
    cursor: pointer;
`;
const MRModalBody = styled.div`
    display: block;
    box-sizing: border-box;
    padding: 10px 20px 10px 20px;
`;
const MRModalBodyInner = styled.div`
    border-radius: 4px;
    padding: 15px;
    background-color: #ffffff;
`;
const MRModalLabel = styled.label`
    color: #a0a0a0;
    font-weight: 600;
    margin-top: 10px;
`;
const MRModalInput = styled.input`
    height: 40px;
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #979797;
`;
const MRModalBackBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #909090;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    border: 2px solid #dadada;
    margin-left: 5px;
    font-size: 11px;
    margin-right: 10px;
    float: right;
    cursor:pointer;
`;

const MRModalFooter = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 20px;
`;
const MRModalCancelBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #909090;
    border: 2px solid #dadada;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    font-size: 11px;
    float: right;
    cursor:pointer;
`;
const MRModalNextBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    margin-left: 5px;
    font-size: 11px;
    margin-left: 10px;
    float: right;
    cursor:pointer;
`;

const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 80px;
    font-size: 13px;
    height: 25px;
    border-radius: 4px;
    margin-left: 5px;
    cursor:pointer;
`;

const MRModalSelect = styled.select`
    display: block;
    box-sizing: border-box;
    height: 40px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    color: #b5b5b5;
`;
const CSLDateCover = styled.div`
    margin-top: 8px;
    width: 95%;
    border-radius: 5px;
    background-color: #ffffff;
    height: 33px;
    padding-top: 5px;
    padding-left: 10px;
    `;

const ModalNameLabelDiv = styled.div`
    height: auto;
    width: 20%;
    padding-top: 7px;
    font-weight: 600;
    color: #979797;
`;
const ModalNameInputDiv = styled.div`
    height: auto;
    width: 80%;
    padding-top:7px;
`;

class UserAssignmentModal extends React.Component
{
    state = {
        users_config: null,
        tags: null,
        cur_user: null,
        view_count: 0,
        dirty: false,
        alert_param: null,
        companies_loading: false,
        users_config_loading: false
    };

    constructor(props) {
        super(props)
        this.saveUserAssignment = this.saveUserAssignment.bind(this)
    }

    componentDidMount()
    {
        let users_config = Store.getStoreData('users_config')
        let tags = Store.getStoreData('tags')
        let cur_user = Store.getStoreData('cur_user')
        this.setState({users_config, tags, cur_user})
    }

    closeModalProcessor = (result, stack) => {
        if(!result)this.setState({alert_param: null});
            else this.props.refreshParentCallback();
    }

    closeModal = (event) => {
        event.preventDefault();
        if(this.state.dirty) {
            let alert_param = {title: 'Warning', message: `You have unsaved changes that will be lost if you close this dialog now. Do you really want to close the dialog?`, ok_text: 'Yes', cancel_text: 'No', confirm: true, 
                           alertHandler: this.closeModalProcessor, stack: {}, body_align: 'left', danger: false}
            this.setState({alert_param: alert_param})
            return
        }
        this.props.closeModal()
        // this.props.refreshParentCallback();
    }

    handleChange = (evt) => {
        // const value = evt.target.value;
        this.setState({[evt.target.name]: evt.target.value});
    }

    processsaveUserAssignment = (result) => {
        console.log('result', result)
        alert('User Assignments saved successfully')
        this.props.refreshParentCallback()
    }

    async saveUserAssignment(event){
        event.preventDefault()
        let api = new APICall()
        let parent_company = Store.getStoreData('parent_company');
        let postData = {command: 'save_user_assignment', parent_id: parent_company.ClientNumber, users_config: this.state.users_config}
        console.log('postData', postData)
        api.command(postData, this.processsaveUserAssignment)
    }

    assignToDivision = (index) => (event) => {
        event.preventDefault()
        let div = Store.getStoreData('divs_data')[index]
        let cur_user = Store.getStoreData('cur_user')
        console.log('div in assign', div)
        let users_config = this.state.users_config
        if(!(cur_user.id in users_config)) {
            users_config[cur_user.id] = {tags:{'1':{},'2':{}, '3':{}}, companies:{}}
        }
        if(div.id in users_config[cur_user.id].tags['1']) {
            delete users_config[cur_user.id].tags['1'][div.id]
        } else {
            users_config[cur_user.id].tags['1'][div.id] = 1
        }
        let dirty = true
        this.setState({users_config, dirty})
    }

    assignToRegion = (index) => (event) => {
        event.preventDefault()
        let div = Store.getStoreData('regs_data')[index]
        let cur_user = Store.getStoreData('cur_user')
        console.log('div in assign', div)
        let users_config = this.state.users_config
        if(!(cur_user.id in users_config)) {
            users_config[cur_user.id] = {tags:{'1':{},'2':{}, '3':{}}, companies:{}}
        }
        if(div.id in users_config[cur_user.id].tags['2']) {
            delete users_config[cur_user.id].tags['2'][div.id]
        } else {
            users_config[cur_user.id].tags['2'][div.id] = 1
        } 
        let dirty = true
        this.setState({users_config, dirty})
    }

    assignToCompany = (index) => (event) => {
        event.preventDefault()
        let company =  Store.getStoreData('group_companies')[index]
        let cur_user = Store.getStoreData('cur_user')
        let users_config = this.state.users_config
        if(!(cur_user.id in users_config)) {
            users_config[cur_user.id] = {tags:{'1':{},'2':{}, '3':{}}, companies:{}}
        }
        if(company.id in users_config[cur_user.id].companies) {
            delete users_config[cur_user.id].companies[company.id]
        } else {
            users_config[cur_user.id].companies[company.id] = 1
        } 
        let dirty = true
        this.setState({users_config, dirty})
    }


    processHubsData = () => {
        let divs = this.state.tags !== null ? this.state.tags['3'] : []
        let cur_user = this.state.cur_user !== null ? this.state.cur_user : {}
        let user_config = this.state.users_config !== null && cur_user.id in this.state.users_config ? this.state.users_config[cur_user.id.toString()] : {tags:{'1':{},'2':{}, '3':{}}, companies:{}}
        let ret = {data: [], columns: []};
        let i = 0;
        console.log('divs', divs)
        console.log('user_config', user_config)
        ret.columns =[  {Header: '#', accessor: 'index', width: 80, headerStyle: {textAlign: 'left'}}, 
                        {Header: 'Region Name', accessor: 'name', width: 200, headerStyle: {textAlign: 'left', minWidth: 180}}, 
                        {'Header' : 'Action', Cell: row => (
                           <div>
                                {
                                    (() => {
                                        if(row.original.assigned) {
                                            return <MRButton onClick={this.assignToHub(row.index)}>De-assign</MRButton>
                                        } else {
                                            return <MRButton onClick={this.assignToHub(row.index)}>Assign</MRButton>
                                        }

                                    })()

                                        
                                }
                           </div>
                        ), headerStyle: {textAlign: 'center'}}];


        for(let d of divs) {
            i++
            let elem = {
                id: d.id,
                index: i,
                name: d.name,
                assigned: d.id in user_config.tags['3'] ? true : false
            }
            ret.data.push(elem)
        }
        console.log('data', ret.data)
        Store.updateStore('hubs_data', ret.data)

        return ret

    }


    processRegionsData = () => {
        let divs = this.state.tags !== null ? this.state.tags['2'] : []
        let cur_user = this.state.cur_user !== null ? this.state.cur_user : {}
        let user_config = this.state.users_config !== null && cur_user.id in this.state.users_config ? this.state.users_config[cur_user.id.toString()] : {tags:{'1':{},'2':{}, '3':{}}, companies:{}}
        let ret = {data: [], columns: []};
        let i = 0;
        console.log('divs', divs)
        console.log('user_config', user_config)
        ret.columns =[  {Header: '#', accessor: 'index', width: 80, headerStyle: {textAlign: 'left'}}, 
                        {Header: 'Region Name', accessor: 'name', width: 200, headerStyle: {textAlign: 'left', minWidth: 180}}, 
                        {'Header' : 'Action', Cell: row => (
                           <div>
                                {
                                    (() => {
                                        if(row.original.assigned) {
                                            return <MRButton onClick={this.assignToRegion(row.index)}>De-assign</MRButton>
                                        } else {
                                            return <MRButton onClick={this.assignToRegion(row.index)}>Assign</MRButton>
                                        }

                                    })()

                                        
                                }
                           </div>
                        ), headerStyle: {textAlign: 'center'}}];


        for(let d of divs) {
            i++
            let elem = {
                id: d.id,
                index: i,
                name: d.name,
                assigned: d.id in user_config.tags['2'] ? true : false
            }
            ret.data.push(elem)
        }
        console.log('data', ret.data)
        Store.updateStore('regs_data', ret.data)

        return ret

    }

    processCompaniesData = () => {
        let cur_user = this.state.cur_user !== null ? this.state.cur_user : {}
        let companies = Store.getStoreData('group_companies')
        console.log('companies in process', companies)
        let ret = {data: [], columns: []};
        let user_config = this.state.users_config !== null && cur_user.id in this.state.users_config ? this.state.users_config[cur_user.id.toString()] : {tags:{'1':{},'2':{}, '3':{}}, companies:{}}
        ret.columns =[  {Header: '#', accessor: 'index', width: 80, headerStyle: {textAlign: 'left'}}, 
                        {Header: 'Company Name', accessor: 'name', width: 200, headerStyle: {textAlign: 'left', minWidth: 180}}, 
                        {'Header' : 'Action', Cell: row => (
                           <div>
                                {
                                    (() => {
                                        if(row.original.assigned) {
                                            return <MRButton onClick={this.assignToCompany(row.index)}>De-assign</MRButton>
                                        } else {
                                            return <MRButton onClick={this.assignToCompany(row.index)}>Assign</MRButton>
                                        }

                                    })()

                                        
                                }
                           </div>
                        ), headerStyle: {textAlign: 'center'}}];
        let i = 0
        for(let d of companies) {
            i++
            let elem = {
                id: d.id,
                index: i,
                name: d.company_name,
                assigned: d.id in user_config.companies ? true : false
            }
            ret.data.push(elem)
        }
        console.log('data', ret.data)
        return ret
    }

    assignToHub = (index) => (event) => {
        event.preventDefault()
        let div = Store.getStoreData('hubs_data')[index]
        let cur_user = Store.getStoreData('cur_user')
        console.log('div in assign', div)
        let users_config = this.state.users_config
        if(!(cur_user.id in users_config)) {
            users_config[cur_user.id] = {tags:{'1':{},'2':{}, '3':{}}, companies:{}}
        }
        if(div.id in users_config[cur_user.id].tags['3']) {
            delete users_config[cur_user.id].tags['3'][div.id]
        } else {
            users_config[cur_user.id].tags['3'][div.id] = 1
        } 
        let dirty = true
        this.setState({users_config, dirty})
    }


    assignToGrouping = (grouping, index) => (event) => {
        event.preventDefault()
        let groupings = Store.getStoreData(grouping + '_data')[index]
        let cur_user = Store.getStoreData('cur_user')
        Utils.log('groupings in assign', groupings)
        let users_config = this.state.users_config
        let init_tags = {}
        let tags = Store.getStoreData('tags_config')
        let grouping_tags = Store.getStoreData('grouping_tags')
        for(let t of tags) {
            let key = Object.keys(t)[0]
            init_tags[key] = {}
        }
        if(!(cur_user.id in users_config)) {
            users_config[cur_user.id] = {tags:init_tags, companies:{}}
        } else {
            for(let k in init_tags) {
                if(!(k in users_config[cur_user.id].tags)) {
                    users_config[cur_user.id].tags[k] = {}
                }
            }
            for(let k in users_config[cur_user.id].tags) {
                if(!(k in init_tags)) delete users_config[cur_user.id].tags[k]
            }
        }
        if(groupings.id in users_config[cur_user.id].tags[grouping]) {
            delete users_config[cur_user.id].tags[grouping][groupings.id]
        } else {
            users_config[cur_user.id].tags[grouping][groupings.id] = 1
        } 
        let dirty = true
        this.setState({users_config, dirty})
    }

    processGroupingsData = (stack) => {
        let grouping = stack.grouping
        let ret = {data: [], columns: []};
        let groupings = this.state.tags !== null && grouping in this.state.tags ? this.state.tags[grouping] : []
        let cur_user = this.state.cur_user !== null ? this.state.cur_user : {}
        let init_tags = {}
        let tags = Store.getStoreData('tags_config')
        let grouping_tags = Store.getStoreData('grouping_tags')
        for(let t of tags) {
            let key = Object.keys(t)[0]
            init_tags[key] = {}
        }
        let user_config = this.state.users_config !== null && cur_user.id in this.state.users_config ? this.state.users_config[cur_user.id.toString()] : {tags:init_tags, companies:{}}
        ret.columns =[  {Header: '#', accessor: 'index', width: 80, headerStyle: {textAlign: 'left'}}, 
                        {Header: grouping_tags[grouping] + ' Name', accessor: 'name', width: 200, headerStyle: {textAlign: 'left', minWidth: 180}}, 
                        {'Header' : 'Action', Cell: row => (
                           <div>
                                {
                                    (() => {
                                        if(row.original.assigned) {
                                            return <MRButton onClick={this.assignToGrouping(grouping, row.index)}>De-assign</MRButton>
                                        } else {
                                            return <MRButton onClick={this.assignToGrouping(grouping, row.index)}>Assign</MRButton>
                                        }

                                    })()

                                        
                                }
                           </div>
                        ), headerStyle: {textAlign: 'center'}}];
        let i = 0
        Utils.log('user_config, groupings', user_config, groupings)
        for(let d of groupings) {
            i++
            let elem = {
                id: d.id,
                index: i,
                name: d.name,
                assigned: grouping in user_config.tags && d.id in user_config.tags[grouping] ? true : false
            }
            ret.data.push(elem)
        }
        // console.log('data', ret.data)
        Store.updateStore(grouping + '_data', ret.data)

        return ret
    }

    processDivsData = () => {
        let divs = this.state.tags !== null ? this.state.tags['1'] : []
        let cur_user = this.state.cur_user !== null ? this.state.cur_user : {}
        let user_config = this.state.users_config !== null && cur_user.id in this.state.users_config ? this.state.users_config[cur_user.id.toString()] : {tags:{'1':{},'2':{}, '3':{}}, companies:{}}
        let ret = {data: [], columns: []};
        let i = 0;
        console.log('divs', divs)
        console.log('user_config', user_config)
        ret.columns =[  {Header: '#', accessor: 'index', width: 80, headerStyle: {textAlign: 'left'}}, 
                        {Header: 'Division Name', accessor: 'name', width: 200, headerStyle: {textAlign: 'left', minWidth: 180}}, 
                        {'Header' : 'Action', Cell: row => (
                           <div>
                                {
                                    (() => {
                                        if(row.original.assigned) {
                                            return <MRButton onClick={this.assignToDivision(row.index)}>De-assign</MRButton>
                                        } else {
                                            return <MRButton onClick={this.assignToDivision(row.index)}>Assign</MRButton>
                                        }

                                    })()

                                        
                                }
                           </div>
                        ), headerStyle: {textAlign: 'center'}}];


        for(let d of divs) {
            i++
            let elem = {
                id: d.id,
                index: i,
                name: d.name,
                assigned: d.id in user_config.tags['1'] ? true : false
            }
            ret.data.push(elem)
        }
        console.log('data', ret.data)
        Store.updateStore('divs_data', ret.data)

        return ret
    }


    render()
    {
        // let tags = Store.getStoreData('tags')
        console.log(this.state);
        // console.log('tags',tags);
        let grouping_tags = Store.getStoreData('grouping_tags')
        return (
            <MRModalContainer>
                <MRModalHeader>
                    <MRModalHeaderText>Assign Users to Group Companies</MRModalHeaderText>
                    <MRModalHeaderCloseBtn onClick={this.closeModal}><FaTimes /></MRModalHeaderCloseBtn>
                    <div style={{clear: "both"}}></div>
                </MRModalHeader>
                <MRModalFooter style={{height: 60}}>
                    <MRModalNextBtn onClick={this.saveUserAssignment}>Save</MRModalNextBtn><MRModalCancelBtn onClick={this.closeModal}>Cancel</MRModalCancelBtn>
                </MRModalFooter>
                <MRModalBody>
                    <MRModalBodyInner>
                        {
                            Object.keys(grouping_tags).map((g, i) => {
                                return (<CSLTable add={false} tableRows={10} processData={this.processGroupingsData} 
                                processDataParams={{grouping: g}}
                                headerText={ Store.getStoreData('parent_company').CompanyName + `: User Assignment to ${grouping_tags[g]}s for : ` + Store.getStoreData('cur_user').name }
                                refreshCallback={this.refreshState} />)
                            })
                        }
                        <CSLTable add={false} tableRows={10} processData={this.processCompaniesData} 
                            headerText={ Store.getStoreData('parent_company').CompanyName + ': User Assignment to Companies for : ' + Store.getStoreData('cur_user').name } 
                            refreshCallback={this.refreshState} />
                    </MRModalBodyInner>
                </MRModalBody>
                <MRModalFooter>
                    <MRModalNextBtn onClick={this.saveUserAssignment}>Save</MRModalNextBtn><MRModalCancelBtn onClick={this.closeModal}>Cancel</MRModalCancelBtn>
                </MRModalFooter>
                <AlertBox alertParam = {this.state.alert_param} />
            </MRModalContainer>
        );
    }
}
export default UserAssignmentModal;