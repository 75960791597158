import React from 'react';
import styled from 'styled-components';
import {FaTimes} from 'react-icons/fa';
import Store from '../../Common/Store';
import DatePicker from 'react-date-picker';
// import QuestionAnswerForm from '../QuestionAnswerForm';
import * as Style from '../Common/StyledComponents';
import Dropzone from '../Common/Dropzone.js'
import {FiDownload} from 'react-icons/fi'
import { saveAs } from 'file-saver';
import APICall from '../../Common/APICall';
import Utils from '../../Common/Utils';

const MRModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    position: absolute;
    z-index: 1001;
    background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 0 20px #a7a6a6;
    padding: 20px;
    width: 50%;
    left: 25%;
    top:40px;
`;
const MRModalHeader = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 20px 20px 10px 20px;
`;
const MRModalHeaderText = styled.div`
    box-sizing: border-box;
    float: left;
    color: #a0a0a0;
`;
const MRModalHeaderCloseBtn = styled.div`
    box-sizing: border-box;
    float: right;
    font-size: 16px;
    color: #a0a0a0;
    cursor: pointer;
`;
const MRModalBody = styled.div`
    display: block;
    box-sizing: border-box;
    padding: 10px 20px 10px 20px;
`;
const MRModalBodyInner = styled.div`
    border-radius: 4px;
    padding: 15px;
    background-color: #ffffff;
`;
const MRModalLabel = styled.label`
    color: #a0a0a0;
    font-weight: 600;
    margin-top: 10px;
`;
const MRModalInput = styled.input`
    height: 40px;
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #979797;
`;
const MRModalBackBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #909090;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    border: 2px solid #dadada;
    margin-left: 5px;
    font-size: 11px;
    margin-right: 10px;
    float: right;
`;

const MRModalFooter = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 20px;
`;
const MRModalCancelBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #909090;
    border: 2px solid #dadada;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    font-size: 11px;
    float: right;
`;
const MRModalNextBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    margin-left: 5px;
    font-size: 11px;
    margin-left: 10px;
    float: right;
`;
const MRModalSelect = styled.select`
    display: block;
    box-sizing: border-box;
    height: 40px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    color: #b5b5b5;
`;
const CSLDateCover = styled.div`
    margin-top: 8px;
    width: 95%;
    border-radius: 5px;
    background-color: #ffffff;
    height: 33px;
    padding-top: 5px;
    padding-left: 10px;
    `;

const ModalNameLabelDiv = styled.div`
    height: auto;
    width: 20%;
    padding-top: 7px;
    font-weight: 600;
    color: #979797;
`;
const ModalNameInputDiv = styled.div`
    height: auto;
    width: 80%;
    padding-top:7px;
`;

class AddRegionModal extends React.Component
{
    state = {
        region_name: ''
    };

    constructor(props) {
        super(props)
        this.doRegionCreate = this.doRegionCreate.bind(this)
    }

    componentDidMount()
    {
        let region_modal_data = Store.getStoreData('region_modal_data')
        if(region_modal_data !== null) {
            this.setState({region_name: region_modal_data.name})
        }
    }

    closeModal = (event) => {
        event.preventDefault();
        this.props.refreshParentCallback(null);
    }

    handleChange = (evt) => {
        // const value = evt.target.value;
        this.setState({[evt.target.name]: evt.target.value});
    }

    processRegionCreate = (result) => {
        console.log('result', result)
        alert('Region data saved successfully.')
        this.props.refreshParentCallback()
    }


    async doRegionCreate(event){
        event.preventDefault()
        let tags = Store.getStoreData('tags')
        let index = Store.getStoreData('region_modal_data_index')
        let region_modal_data = Store.getStoreData('region_modal_data')
        let parent_company = Store.getStoreData('parent_company')
        console.log('parent_company', parent_company)
        if(region_modal_data === null) {
            tags['2'].push({id: Utils.genKey(6), name: this.state.region_name})
        } else {
            tags['2'][index].name = this.state.region_name
        }
        let api = new APICall()
        let postData = {command: 'save_tags', tags: tags, parent_company_id: parent_company.ClientNumber }
        await api.command(postData, this.processRegionCreate)
    }


    render()
    {
        console.log(this.state);
        return (
            <MRModalContainer style={{top: '600px'}}>
                <MRModalHeader>
                    <MRModalHeaderText>Add/Edit a Region</MRModalHeaderText>
                    <MRModalHeaderCloseBtn onClick={this.closeModal}><FaTimes /></MRModalHeaderCloseBtn>
                    <div style={{clear: "both"}}></div>
                </MRModalHeader>
                <MRModalBody>
                    <MRModalBodyInner>
                        <MRModalLabel>Region name</MRModalLabel>
                        <MRModalInput type="text" name="region_name" onChange={this.handleChange} value={this.state.region_name} />
                    </MRModalBodyInner>
                </MRModalBody>
                <MRModalFooter>
                    <MRModalNextBtn onClick={this.doRegionCreate}>Save</MRModalNextBtn><MRModalCancelBtn onClick={this.closeModal}>Cancel</MRModalCancelBtn>
                </MRModalFooter>
            </MRModalContainer>
        );
    }
}
export default AddRegionModal;