import React from 'react';
import styled from 'styled-components';
import Store from '../Common/Store.js';
import CSLHeader from './Common/CSLHeader';
import GCIndex from './GC/GCIndex';
import CSLIndex from './CSL/CSLIndex';
import TaskHeader from './Common/TaskHeader';
import WelcomeBanner from './Common/WelcomeBanner';
import * as Style from './Common/StyledComponents'
import CSLLoader from './Common/CSLLoader'

class Index extends React.Component
{
	//state = {authuser: null, OverlayHeight: null, OverlayForCSLModal: null, FCKOverlayHeight: null, ContactName: null, OverlayForModal: null, CheckModalOverlay: null, MGRAddFileCheckModal: null, ReportModal: null, rid: 0};
	state = { view_count: 0, cur_view: null, modal_view: null }

	constructor(props) {
		super(props);
	}

	componentDidMount = () => {
		let cur_view = 'index'
		let parent_id = this.props.match ? this.props.match.params.companyId : 0;
		Store.updateStore('cur_view', cur_view);
		console.log("componentDidMount===>", cur_view)
		this.setState({cur_view : cur_view, parent_id: parent_id});
	}

	refreshState = (modal_view) => {
		this.setState({modal_view: modal_view, cur_view: Store.getStoreData('cur_view')})
	}

	refreshThisState = (view) => {
		this.setState({cur_view: view});
	}


	render()
	{
		console.log("this.state.cur_view", this.state.cur_view);
		return (
		  	<div style={{backgroundColor: "#f2f2f3", position: "relative"}}>
		  	<WelcomeBanner />
		  	  	{
					(() => {
						switch(this.state.cur_view) {
							case 'index' : return <CSLIndex refreshParentCallback={this.refreshState} parentId={this.state.parent_id} />; break;
							case 'gc_index' : return <GCIndex refreshParentCallback={this.refreshState} />; break;
							default: return <Style.NoPermission>Sorry, you do not have permission to view this page. </Style.NoPermission>;
						}
					})()
		  	  	}
		  	<CSLLoader />
		  	</div>
		);
	}
}

export default Index;