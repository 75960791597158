import React from 'react';
import styled from 'styled-components';
import WelcomeDateTime from './WelcomeDateTime';
import * as Style from './StyledComponents'
import { FaHome } from "react-icons/fa";
import Store from '../../Common/Store'

class WelcomeBanner extends React.Component
{
    render()
    {
        let url = '/'
        return (
            <Style.BannerContainer>
                <Style.Banner>
                    <Style.WelcomeText><a href={url} style={{color: '#ffffff'}}><FaHome /> Group Companies Configuration</a></Style.WelcomeText>
                    <WelcomeDateTime />
                    <div style={{clear: "both"}}></div>
                </Style.Banner>
            </Style.BannerContainer>
        );
    }
}

export default WelcomeBanner;
