import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store.js';
import Utils from '../../Common/Utils.js';
import APICall from '../../Common/APICall.js';
import * as Style from '../Common/StyledComponents'
import CSLTableAlt from '../Common/CSLTableAlt'
import AlertBox from '../Common/AlertBox'
import {FaPlus, FaPencilAlt, FaTimes, FaEllipsisV, FaUser} from 'react-icons/fa';

const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #046DE4;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 100px;
    font-size: 14px;
    height: 35px;
    border-radius: 4px;
    margin-left: 5px;
    margin-top: 5px;
    cursor: pointer;
`;
const MenuBar = styled.div`
    height: 35px;
    width: calc(100% - 10px);
    background-color: #F9FAFB;
    position: relative;
    color: #9CA4B2;
    border-bottom-style: solid;
    border-bottom-color:  #FEEBF4;
    border-bottom-width: 2px;
    padding-top: 3px;
    padding-left: 0px;
    margin-top: 5px;
`;
const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 100vh;
  width: calc(100vw - 330px);
  background-color: #ffffff;
  position: fixed;
  left: 300px;
  top: 80px;
  z-index: 1000;
  opacity: 0.8;
`;

const ModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    width: calc(50vw - 10px);
    position: fixed;
    top: 20vh;
    left: 40vw;
    z-index: 1001;
    background-color: #F3F7FB;
    box-shadow: 0 0 5px #a7a6a6;
    margin-bottom: 10px;
    border-radius: 5px;
    background-color: white;
`;

const ModalHeader = styled.div`
    background-color: white;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 14px;
    font-weight: 700;
    height: 60px;
    color: #046DE4;
    width: calc(100% - 12px);
`;
const ModalBodyInner = styled.div`
    border-radius: 4px;
    padding: 15px;
    background-color: #ffffff;
`;
const ModalLabel = styled.div`
    color: #242424;
    font-weight: 600;
    margin-top: 10px;
    font-size: 16px;
    height: 20px;
    padding-bottom: 5px;
    margin-bottom: 5px;
`;
const ModalInput = styled.input`
    height: 40px;
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    font-family: 'Montserrat', sans-serif;
    color: #242424;
`;

const ModalHeaderText = styled.div`
    float: left;
    font-weight: bold;
    box-sizing: border-box;
    font-size: 24px;
    margin-top: 16px;
    padding-left: 25px;
    background-color: white;
    color: #046DE4;
    font-family: 'Montserrat', sans-serif;
`;

const ModalBody = styled.div`
    position: relative;
    display: block;
    box-sizing: border-box;
    background-color: #F3F7FB;
    padding: 10px;
    border-radius: 4px;
`;
const ModalHeaderCloseBtn = styled.div`
    float: right;
    box-sizing: border-box;
    margin-top: 16px;
    margin-right: 20px;
    color: #949494;
    font-size: 16px;
    cursor: pointer;
`;

class AddGroupingDialog extends React.Component {
    state = {grouping_id: '_new', name: '', alert_param: null}

    componentDidMount = () => {
        let groupings = Store.getStoreData('groupings')
        let name = this.props.editGroup in groupings ? groupings[this.props.editGroup] : ''
        this.setState({grouping_id: this.props.editGroup, name: name})
    }

    handleChange = (evt) => {
        this.setState({[evt.target.name]: evt.target.value});
    }

    saveFormProcessorFinal = () => {
        this.props.closeDialogSave()
    }

    saveFormProcessor = (result) => {
        let groupings = Store.getStoreData('groupings')
        let alert_param = {title: 'Success', message: `Grouping saved.`, ok_text: 'Ok', cancel_text: 'No', confirm: false, 
                           alertHandler: this.saveFormProcessorFinal, stack:null, body_align: 'center', danger: false}
        this.setState({alert_param: alert_param})
    }

    saveForm = () => {
        let tags = Store.getStoreData('tags')
        let tags_config = Store.getStoreData('tags_config')
        let grouping_id = this.state.grouping_id === '_new' ? Utils.genKey(5) : this.state.grouping_id
        if(!(grouping_id in tags)){
            let elem = {}; elem[grouping_id] = this.state.name;
            tags_config.push(elem)
            tags[grouping_id] = []
        } else {
            for(let t of tags_config) {
                let g_id = Object.keys(t)[0]
                if(g_id === grouping_id)t[g_id] = this.state.name
            }
        }

        let groupings = {}
        for(let t of tags_config) {
            let t_id = Object.keys(t)[0]
            groupings[t_id] = t[t_id] 
        }

        Store.updateStore('tags', tags)
        Store.updateStore('groupings', groupings)
        Store.updateStore('tags_config', tags_config)
        let api = new APICall()
        let post_data = {command: 'client_save_tags_config', tags: tags, tags_config: tags_config}
        Utils.log('post_data', post_data)
        api.command(post_data, this.saveFormProcessor)
    }

    render() {

        return (<div>
              <ModalContainer>
                <ModalHeader>
                    <ModalHeaderText>{this.props.editGroup === '_new' ? 'New Grouping' : 'Edit Grouping'}</ModalHeaderText>
                    <Style.ModalHeaderCloseBtn onClick={() => this.props.closeDialog()}><FaTimes /></Style.ModalHeaderCloseBtn>
                </ModalHeader>
                <ModalBody>
                    <ModalBodyInner>
                        <ModalLabel>Grouping name</ModalLabel>
                        <div style={{clear: 'both'}}></div>
                        <ModalInput type="text" name="name" onChange={this.handleChange} value={this.state.name} />
                    </ModalBodyInner>
                </ModalBody>
                <Style.ModalHeader>
                    <MRButton style={{float: 'right'}} onClick={() => this.saveForm()}>Save</MRButton>
                    <MRButton style={{float: 'right', backgroundColor: 'white', color: '#046DE4'}} onClick={() => this.props.closeDialog()}>Cancel</MRButton>
                </Style.ModalHeader>
              </ModalContainer>
              <AlertBox alertParam={this.state.alert_param} />
            </div>)
    }
}

export default AddGroupingDialog;
