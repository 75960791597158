import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store.js';
import Utils from '../../Common/Utils.js';
import APICall from '../../Common/APICall.js';
import * as Style from '../Common/StyledComponents'
import CSLTableAlt from '../Common/CSLTableAlt'
import AlertBox from '../Common/AlertBox'
import {FaPlus, FaPencilAlt, FaTimes, FaEllipsisV, FaUser, FaRedo} from 'react-icons/fa';
import AssignUsersDialog from './AssignUsersDialog';
import ComDecom from '../../Common/ComDecom.js';

const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #046DE4;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 50px;
    font-size: 13px;
    height: 25px;
    border-radius: 4px;
    margin-left: 5px;
    cursor: pointer;
`;
const MenuBar = styled.div`
    height: 35px;
    width: calc(100% - 10px);
    background-color: #F9FAFB;
    position: relative;
    color: #9CA4B2;
    border-bottom-style: solid;
    border-bottom-color:  #FEEBF4;
    border-bottom-width: 2px;
    padding-top: 3px;
    padding-left: 0px;
    margin-top: 5px;
`;
const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 100vh;
  width: calc(100vw - 330px);
  background-color: #ffffff;
  position: fixed;
  left: 300px;
  top: 80px;
  z-index: 1000;
  opacity: 0.8;
`;

const ModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: calc(100vh - 160px);
    width: calc(100vw - 340px);
    position: fixed;
    top: 150px;
    left: 310px;
    z-index: 1001;
    background-color: #F3F7FB;
    box-shadow: 0 0 5px #a7a6a6;
    margin-bottom: 10px;
    border-radius: 5px;
    background-color: white;
`;

const ModalHeader = styled.div`
    background-color: white;
    padding-top: 5px;
    font-size: 14px;
    font-weight: 700;
    height: 50px;
    color: #046DE4;
    width: calc(100% - 12px);
`;

const ModalHeaderText = styled.div`
    float: left;
    font-weight: bold;
    box-sizing: border-box;
    font-size: 24px;
    margin-top: 16px;
    padding-left: 30px;
    background-color
    color: #046DE4;
    font-family: 'Montserrat', sans-serif;
`;

const ModalBody = styled.div`
    position: relative;
    display: block;
    box-sizing: border-box;
    background-color: #F3F7FB;
    padding: 10px;
    border-radius: 4px;
`;
const ModalHeaderCloseBtn = styled.div`
    float: right;
    box-sizing: border-box;
    margin-top: 16px;
    margin-right: 20px;
    color: #949494;
    font-size: 16px;
    cursor: pointer;
`;


class AssignUsers extends React.Component
{
	state = {members: {}, alert_param: null, dialog_view: null, user_id: '0', updated_in: 1}

	processAPICall = (result) => {
	Utils.log('AssignUsers result', result)
	let members = ComDecom.deCompress(result.result.members, true, 'ID');
    let updated_in = result.result.updated_in
    Store.updateStore('members', members)
    Store.updateStore('updated_in', updated_in)
		this.setState({members, updated_in})
	}

  refreshMembers = () => {
    let api = new APICall()
    let post_data = {command: 'client_get_contacts', force_refresh: true}
    api.command(post_data, this.processAPICall)
  }

	setComponentState = () => {
		let members = Store.getStoreData('members')
		if(members === null) {
	        let api = new APICall()
	        let post_data = {command: 'client_get_contacts'}
	        api.command(post_data, this.processAPICall)
		} else {
			this.setState({members})
		}
	}

	componentDidMount() {
		this.setComponentState()
	}

	componentDidUpdate(prevprops) {
		if(this.props === prevprops)return

		this.setComponentState()
	}

	nameCompare = (a,b) => {
		return a.ContactName.localeCompare(b.ContactName)
	}

	userAssignmentDialog = (id) => {
    this.setState({user_id: id, dialog_view: 'user_assign'})
	}

  closeDialog = () => {
    this.setState({user_id: '0', dialog_view: null})
  }

  processMembersData = () => {
        let members_assoc = this.state.members;
        let members = []
        for(let id in members_assoc) {
        	members.push(members_assoc[id])
        }
        members.sort(this.nameCompare)
              
        let ret = {data: [], columns: []};
        let i = 1;
        ret.columns =[{Header: 'Name', accessor: 'contact_name', minWidth: 150, headerStyle: {textAlign: 'left'}},   
                      {Header: 'Email', accessor: 'email', minWidth: 150, headerStyle: {textAlign: 'left'}}, 
                        {'Header' : 'Action', Cell: row => (
                           <div>
                               <MRButton style={{width: '120px'}} onClick={() => this.userAssignmentDialog(row.original.id)}>Assignments</MRButton>
                           </div>
                        ), headerStyle: {textAlign: 'left'}}
                     ];
                     for(let k = 0 ; k< members.length ; k++) 
                     {  
                        let elem = {'index': k,
                                            'contact_name': members[k].ContactName,
                                            'email' : members[k].EmailAddress,
                                            'id' : members[k].ID
                                           };
                        ret.data.push(elem);                           
                       
                      }   
        Store.updateStore('members_data', ret.data)
        return ret;      
	}
	render() {
    let rows_num = Object.keys(this.state.members).length + 3
    // Utils.log('result rows_num', rows_num)
    let min_string = this.state.updated_in === 1 ? 'minute.' : 'minutes.'
		return (<div style={{width: '100%'}}>
        <MenuBar><MRButton onClick={() => this.refreshMembers()} title='Refresh list' style={{float: 'right'}}><FaRedo /></MRButton>
          <div style={{float: 'right', color:'#282828', paddingTop: 4}}>{`Updated in last ${this.state.updated_in} ${min_string}  `}</div>
        </MenuBar>
        
				<CSLTableAlt add={false} processData={this.processMembersData} headerText={null} tableRows={rows_num} tableWidth='calc(100% - 10px)' /> 
        <AlertBox alertParam={this.state.alert_param} />
        {
          (() => {
            if(this.state.dialog_view !== null) {
              switch(this.state.dialog_view) {
                case 'user_assign': return (<AssignUsersDialog userId={this.state.user_id} closeDialog={this.closeDialog} />); break;
              }
            }
          })()
        }
			</div>)
	}
}

export default AssignUsers;