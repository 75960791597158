import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store.js';
import * as Style from '../Common/StyledComponents'

class ModuleConfigPartial extends React.Component
{
	state = {module_config: []}

	componentDidMount() {
		this.setState({module_config: this.props.moduleConfig})
	}

	render() {
		return <div></div>
	}
}

export default ModuleConfigPartial;