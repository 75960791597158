import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store.js';
import Utils from '../../Common/Utils.js';
import APICall from '../../Common/APICall.js';
import * as Style from '../Common/StyledComponents'
import ClientBanner from '../Common/ClientBanner'
import AlertBox from '../Common/AlertBox'
import ClientWelcome from '../Common/ClientWelcome'
import AssignUsers from './AssignUsers.js'
import GroupCompanies from './GroupCompanies.js'
import Groupings from './Groupings.js'
import {FaPlus, FaPencilAlt, FaTimes, FaTrash, FaEllipsisV, FaUser} from 'react-icons/fa';
import AddGroupingDialog from './AddGroupingDialog'
import CSLLoader from '../Common/CSLLoader'

const LeftMenu = styled.div`
	width: 300px;
	float: left;
	height: calc(100vh - 200px);
	padding: 10px 10px 10px 10px;
	margin-top: 12px;
	margin-left: 10px;
	border-right: 5px solid #E4D0FF;
`
const ContentArea = styled.div`
	width: calc(100% - 410px);
	float: right;
	height: calc(100vh - 200px);
	margin-top: 0px;
	padding: 4px 10px 10px 10px;
	margin-right: 10px;
`
const ActiveMenuItem = styled.div`
	width: 280;
	height: 35px;
	margin-top: 8px;
	margin-right: 10px;
	color: #046DE4;
	font-weight: 700;
	font-size: 16px;
	cursor: pointer;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 230px;
    overflow: hidden;
`
const AddGrouping = styled.div`
	width: 280;
	height: 45px;
	margin-top: 15px;
	margin-right: 10px;
	color: #046DE4;
	font-weight: 700;
	font-size: 18px;
	cursor: pointer;
`
const InactiveMenuItem = styled.div`
	width: 280;
	height: 35px;
	margin-top: 8px;
	margin-right: 10px;
	color: #272727;
	font-weight: 400;
	font-size: 16px;
	cursor: pointer;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 230px;
    overflow: hidden;
`

class MenuItem extends React.Component {
	handleClick = () => {
		if('onClick' in this.props)this.props.onClick(this.props.menu_id)
	}

	render() {
		let indent = 'indent' in this.props ? this.props.indent : false
		let margin_left = indent ? 20 : 10
		let font_size = indent ? 14 : 16
		let cursor = 'onClick' in this.props ? 'pointer' : 'default'
		Utils.log('cursor', cursor)
		if(this.props.active){
			return <ActiveMenuItem title={this.props.title} style={{color: 'onClick' in this.props ? '#2727E7' : '#272727', cursor: cursor, marginLeft: margin_left, fontSize: font_size }}>{this.props.text}</ActiveMenuItem>
		} else {
			return <InactiveMenuItem title={this.props.title} style={{marginLeft: margin_left, cursor: cursor, fontSize: font_size}} onClick={this.handleClick}>{this.props.text}</InactiveMenuItem>
		}
	}
}

class LeftMenuPane extends React.Component {
	state = {groupings: {}, active_menu: 'usr', dialog_view: null}

	groupings = {}

    processAPICall = (result) => {
        Utils.log('LeftMenuPane result', result)
        this.groupings = {}
        for(let g of result.result.tags_config) {
        	this.groupings[Object.keys(g)[0]] = g[Object.keys(g)[0]]
        }
        let tags_lookup = {}
        for(let t in result.result.tags) {
            tags_lookup[t] = {}
            for(let v of result.result.tags[t]) {
                tags_lookup[t][v.id] = v.name
            }
        }
        Store.updateStore('groupings', this.groupings)
        Store.updateStore('tags_lookup', tags_lookup)
        Store.updateStore('tags', result.result.tags)
        Store.updateStore('tags_config', result.result.tags_config)
        this.componentSetState()
    }

    showAddGroupingDialog = () => {
    	this.setState({dialog_view: 'add_grouping'})
    }

	componentSetState = () => {
		let groupings = Store.getStoreData('groupings')
		if(groupings !== null)this.groupings = groupings
		this.setState({active_menu: this.props.activeMenu})
	}

	componentDidMount() {
        let api = new APICall()
        let post_data = {command: 'client_get_groupings'}
        api.command(post_data, this.processAPICall)
		this.componentSetState()
	}

	closeDialog = () => {
		this.setState({dialog_view: null})
	}

	componentDidUpdate = (prevprops) => {
		if(this.props === prevprops)return
		this.componentSetState()
	}
	setMenu = (active_menu) => {
		this.props.setMenu(active_menu)
	}

	doReload = () => {
		window.location.reload(true)
	}
    nameCompare = (a,b) => {
        return a.name.localeCompare(b.name)
    }

	render() {
		let groupings_list = []
		for(let id in this.groupings) {
			let elem = {name: this.groupings[id], id: id}
			groupings_list.push(elem)
		}
		groupings_list.sort(this.nameCompare)

		return (
				<LeftMenu>
					<MenuItem active={this.state.active_menu === 'usr'} menu_id='usr' text='User Assignments' onClick={this.setMenu} />
					<MenuItem active={this.state.active_menu === 'gcs'} menu_id='gcs' text='Group Companies' onClick={this.setMenu} />
					<MenuItem active={true} menu_id='grp' text='Groupings' />
					{
						groupings_list.map((item,i) => {
							return <MenuItem key={i} 
								active={this.state.active_menu === 'grp_' + item.name} 
								onClick={this.setMenu}
								menu_id={'grp_' + item.id} 
								text={item.name}
								indent={true}
								title={item.name}
							/>
						})
					}
					<AddGrouping onClick={() => this.showAddGroupingDialog('0')}><FaPlus />&nbsp; Add Grouping</AddGrouping>
					{
						(() => {
							if(this.state.dialog_view !== null) {
								switch(this.state.dialog_view) {
									case 'add_grouping': return (<AddGroupingDialog 
																closeDialogSave={this.doReload} 
																closeDialog={this.closeDialog}
																editGroup='_new' />); break;
								}
							}
						})()
					}
				</LeftMenu>
			)
	}
}

class ClientIndex extends React.Component
{
	state = {active_menu: 'usr', view_count: 0, alert_param: null, ready: false}

	setMenu = (active_menu) => {
		this.setState({active_menu: active_menu})
	}

	doReload = () => {
		window.location.reload(true)
	}

	closeDialog = () => {
		this.setState({view_count: this.state.view_count + 1})
	}

	render() {
		// Utils.log('result this.state', this.state)
		return (<div>
			<ClientBanner />
			<ClientWelcome />
			<div>
				<LeftMenuPane setMenu={this.setMenu} activeMenu={this.state.active_menu} count={this.state.view_count} />
				<ContentArea>
				{
					(() => {
						switch(this.state.active_menu) {
							case 'usr': return (<AssignUsers closeDialog={this.closeDialog} count={this.state.view_count} />); break;
							case 'gcs': return (<GroupCompanies closeDialog={this.closeDialog} count={this.state.view_count} />); break;
							default: return (<Groupings closeDialog={this.closeDialog} closeDialogSave={this.doReload} grouping={this.state.active_menu} count={this.state.view_count} />); break;
						}
					})()
				}
				</ContentArea>
			</div>
			<CSLLoader />
		</div>)
	}
}

export default ClientIndex;