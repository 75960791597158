import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store';
import CSLTable from '../Common/CSLTable';
import * as Style from '../Common/StyledComponents'
import APICall from '../../Common/APICall';

const SubmitButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #223B5A;
    color: #ffffff;
    width: 160px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    padding-left: 10px;
    margin-left: 5px;
    font-size: 11px;
`;
const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 200px;
    font-size: 13px;
    height: 25px;
    border-radius: 4px;
    cursor: pointer;
`;

class CSLIndex extends React.Component
{
    state = { modal_view: null, view_count: 0 }

    componentDidMount()
    {
        
        let companies = Store.getStoreData('companies')
        let parent_id = this.props.parentId;
        console.log('parent_id', parent_id)
        let company_index = null
        for(let i=0; i < companies.length; i++) {
            if(companies[i].ClientNumber === parseInt(parent_id)) {
                company_index = i;
            }
        }
        console.log('company_index', company_index)
        if(company_index !== null)this.loadGroupCompaniesPage(company_index)
    }

    refreshState = () => {
        this.setState({view_count: this.state.view_count + 1});
    }

    goToGroupCompaniesPage = (index) => {
        let parent_company = Store.getStoreData('companies')[index]
        console.log('parent_company', parent_company)
        let url = window.location.href;//
        if(url.charAt(url.length - 1) === '/')url = url.substring(0, url.length - 1);
        window.location.href = url + '/' + parent_company.ClientNumber;;
    }

    loadGroupCompaniesPage = (index) => {
        // event.preventDefault();
        let parent_company = Store.getStoreData('companies')[index]
        console.log('parent_company',parent_company)
        Store.updateStore('parent_company', parent_company)
        Store.updateStore('cur_view', 'gc_index')
        this.props.refreshParentCallback(null)
    }

    processCompaniesData = () => {
        let tabledata = Store.getStoreData('companies');
        let ret = {data: [], columns: []};
        let i = 0;
        ret.columns =[  {Header: '#', accessor: 'index', width: 80, headerStyle: {textAlign: 'left'}}, 
                        {Header: 'Name', accessor: 'name', width: 600, headerStyle: {textAlign: 'left', minWidth: 300}}, 
                        {'Header' : 'Action', Cell: row => (
                           <div>
                               <MRButton onClick={() => this.goToGroupCompaniesPage(row.index)}>Group Companies Setup</MRButton>
                           </div>
                        ), headerStyle: {textAlign: 'center'}}];

        for(let d of tabledata) {
            console.log('data element->', d);
            i++;
            let elem = {
                id: d.ClientNumber,
                index : i,
                name : d.CompanyName
            };
            ret.data.push(elem);
        }
        return ret;
    }

    closeModal = () => {
        this.setState({modal_view: null});
    }

    render()
    {
        return (
            <div>
                <div style={{clear: "both"}}></div>
                <CSLTable add={false} tableRows={25} processData={this.processCompaniesData} headerText={'CSL Clients'} refreshCallback={this.refreshState} />
                <div style={{clear: "both"}}></div>
                {
                    // (() => {
                    //     if(this.state.modal_view !== null) {
                    //         switch(this.state.modal_view) {
                    //             case 'csl_mr_modal': return <div><Style.InactiveOverlay /><AddMRModal saveModelReview={this.saveModelReview} closemodal={this.closeModal} rid={this.state.rid} name={this.state.name} show_scores={this.state.show_scores} description={this.state.description} resultconfigs={this.state.resultconfigs} openconfigs={this.state.openconfigs} questions={this.state.questions} /></div>; break;
                    //         }
                    //     }
                    // })()
                }
            </div>
        );
    }
}

export default CSLIndex;