import React from 'react';
import Store from '../../Common/Store';
import { FaHome } from "react-icons/fa";
import { FaUsers } from "react-icons/fa";
import { FaFlag } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouseBlank, faSquareList } from "@fortawesome/pro-thin-svg-icons";
import * as Style from './StyledComponents';
import {Link} from "react-router-dom";

/*global localStorage*/

class ClientBanner extends React.Component
{
	redirectTo = (location) => (event) => {
		event.preventDefault();
		let url = "";
		switch(location) {
			case "home":
				url = "/";
				break;
			default:
				url = "/";
		}
		window.location.assign(url);
	}
	render()
	{
		let use_site_layout = false
		if(localStorage.getItem('site_layout')!==null){
		  let site_layout = JSON.parse(localStorage.getItem('site_layout'));
		  use_site_layout = site_layout.result.use_site_layout;
		}
		if(use_site_layout) return (<div></div>)
		const role = Store.getStoreData('role')
		return (
			<Style.HeadContainer>
				<Style.HomeText style={{fontWeight: "600", cursor: "pointer"}} onClick={this.redirectTo("home")}>
					<FontAwesomeIcon icon={faHouseBlank} style={{marginRight: '5px'}} />
					<span>My Compliance Center / Groupings</span>
				</Style.HomeText>

				{/*<Style.HomeLogo onClick={this.redirectTo("home")} style={{paddingRight:"10px", float:"right", cursor: "pointer"}}><FaHome /></Style.HomeLogo>*/}
				{ role === 'admin_manager' &&
					<Style.HomeLogo title="Audit Logs" style={{marginRight: "10px", float: "right", cursor: "pointer"}}>
						<a href={process.env.REACT_APP_AUDIT_URL} style={{color: '#929292'}}><FontAwesomeIcon icon={faSquareList} style={{marginRight: "10px"}} /></a>
					</Style.HomeLogo>
				}
				<div style={{clear: "both"}}></div>
			</Style.HeadContainer>
		);
	}
}

export default ClientBanner;