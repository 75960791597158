import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store'
import { FaHome, FaCog, FaBook } from "react-icons/fa";
import * as Style from './StyledComponents'

class CSLHeader extends React.Component
{
    setView = (event) => {
        event.preventDefault();
        Store.updateStore('prev_view', this.props.from_view);
        this.props.refreshParentState('register_index');
    }
    render()
    {
        let config_url = '/config';
        return (
            <Style.HeadContainer>
                <Style.HomeLogo><FaHome /></Style.HomeLogo>
                <Style.HomeText><span style={{fontWeight: "600"}}>{this.props.homeText}</span></Style.HomeText>
                <Style.Categories><a href={config_url}><FaCog /></a></Style.Categories>
                {
                    (() => {
                        if (!Store.getStoreData('is_admin')) {
                            return (<Style.Categories style={{cursor: "pointer"}} onClick={this.setView}><FaBook style={{color: "#337ab7"}} /></Style.Categories>);
                        }
                    })()
                }
                <div style={{clear: "both"}}></div>
            </Style.HeadContainer>
        );
    }
}
export default CSLHeader;
