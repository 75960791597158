import React, { Component } from "react";
import "./Dropzone.css";
import styled from 'styled-components';
import {FiDownload} from 'react-icons/fi'
import {MdClear} from 'react-icons/md'
import { saveAs } from 'file-saver';

const FileContainer = styled.div`
    width: 300px;
    display: flex;
`;
const FileInfoContainer = styled.div`
    margin-left:10px;
    color: #969EA2;
    width: 220px;
    height: 70px;
`;
const FileTypeContainer = styled.div`
    width: 100px;
    min-height:70px;
    height: 70px;
    background-color: #fff;
    color: #969EA2;
    font-size:22px;
    text-align:center;
    padding-top:22px;
    font-weight:800;
    border: 1px solid #AA9EA2;
`;

class Dropzone extends Component {

  state = {files: [], bin_files: []};
  binFiles = [];
  numFiles = 0;
  prevNumFiles = 0;
  numFilesLoading = 0;

  constructor(props) {
    super(props);
    this.state = { hightlight: false, got_file: 0, files: [] };
    this.fileInputRef = React.createRef();

    this.openFileDialog = this.openFileDialog.bind(this);
    this.onFilesAdded = this.onFilesAdded.bind(this);
    this.onDragOver = this.onDragOver.bind(this);
    this.onDragLeave = this.onDragLeave.bind(this);
    this.onDrop = this.onDrop.bind(this);
  }

  openFileDialog() {
    if (this.props.disabled) return;
    this.fileInputRef.current.click();
  }

  updateProgress = (evt) => {
    if (evt.lengthComputable) {
      var percentLoaded = Math.round((evt.loaded / evt.total) * 100);
      console.log('percentLoaded',percentLoaded);
    }
  }

  readBinaryFile = (file) => {
    let reader = new FileReader();
    let that = this;
    reader.onprogress = this.updateProgress;
    reader.onload = (function(theFile) {
      return function(e) {
        // let processed_bin = e.target.result.replace(/\n/g,'');
        let fElement = {name: theFile.name, bin_file: window.btoa(e.target.result).replace(/\n/g,'')};
        that.binFiles.push(fElement);
        that.numFiles++;
      };
    })(file);

    reader.readAsBinaryString(file);
  }

  checkFilesUploading = () => {
    if(this.numFiles < this.prevNumFiles + this.numFilesLoading){
      setTimeout(this.checkFilesUploading, 500);
    } else {
      alert('New files loaded. You may safely save your form now.');
      console.log('this.binFiles', this.binFiles);
      this.props.onFilesAdded(this.binFiles);
    }
  }

  onFilesAdded(evt) {
    if (this.props.disabled) return;
    const files = evt.target.files;

    let tempFiles = this.state.files;
    this.numFilesLoading = files.length;
    this.prevNumFiles = this.numFiles;
    setTimeout(this.checkFilesUploading, 500);

    for (var i = 0; i < files.length; i++) {
      tempFiles.push(files.item(i));
      this.readBinaryFile(files.item(i));
    }
    // this.setState({got_file: tempFiles.length, files: tempFiles});
  }

  onDragOver(evt) {
    evt.preventDefault();

    if (this.props.disabled) return;

    this.setState({ hightlight: true });
  }

  onDragLeave() {
    this.setState({ hightlight: false });
  }

  onDrop(event) {
    event.preventDefault();

    if (this.props.disabled) return;

    const files = event.dataTransfer.files;
    if (this.props.onFilesAdded) {
      const array = this.fileListToArray(files);
      this.props.onFilesAdded(array);
    }
    this.setState({ hightlight: false });
  }

  fileListToArray(list) {
    const array = [];
    for (var i = 0; i < list.length; i++) {
      array.push(list.item(i));
    }
    return array;
  }

  handleRemove = (index) => {
    // console.log('remove index', index);
    let tempFiles = this.state.files;
    tempFiles.splice(index,1);
    this.setState({files: tempFiles});
  }

  handleDownload = (index) => {
    // console.log('download index', index);
    if(this.numFiles < this.prevNumFiles + this.numFilesLoading) {
      alert('Please wait, the files are still loading!');
      return;
    }
    // console.log('binfile',this.binFiles[index]);
    var binaryString =window.atob(this.binFiles[index].bin_file);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
        var ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
    }
    var blob = new Blob([bytes]);
    saveAs(blob, this.binFiles[index].name);
  }

  componentDidMount() {
    this.setState({bin_files: this.props.initFiles, files: []})
  }

  render() {
    console.log('state in render', this.state)
    return (
      <div
        className={`Dropzone ${this.state.hightlight ? "Highlight" : ""}`}
        onDragOver={this.onDragOver}
        onDragLeave={this.onDragLeave}
        onDrop={this.onDrop}
        
        style={{paddingTop:'2px', display:'flex', width: '100%'}}
      >
        <input
          ref={this.fileInputRef}
          className="FileInput"
          type="file"
          multiple
          accept=".pdf,.jpg,.jpeg,.png"
          onChange={this.onFilesAdded}
        />
        {
                    (() => {
                            if(this.state.files.length > 0) {
                              return (
                                      <div style={{width:'100%', display: 'flex', overflow: 'auto', minHeight: 100}}>
                                      {
                                      
                                              this.state.files.map((f, index) => {
                                                  console.log('f',f);
                                                  let file_parts = f.name.split('.');
                                                  let ext = 'PDF';
                                                  switch(file_parts[file_parts.length - 1]) {
                                                    case 'jpeg':
                                                    case 'jpg' : ext = 'JPG';break;
                                                    case 'png' : ext = 'PNG'; break;
                                                    default: ext = 'PDF';
                                                  }
                                                  console.log('ext',ext);
                                                  return(
                                                      <FileContainer key={index}>
                                                        <FileTypeContainer>{ext}</FileTypeContainer>
                                                        <FileInfoContainer>
                                                            <strong style={{color: '#000000'}}>{f.name}</strong><br /><br />
                                                            Added just now<br /><br />
                                                            <a style={{ cursor: 'pointer'}} onClick={() => this.handleDownload(this.prevNumFiles + index)}><FiDownload /> Download</a> &nbsp; 
                                                            <a style={{ cursor: 'pointer'}} onClick={() => this.handleRemove(index)}><MdClear /> Remove</a>
                                                        </FileInfoContainer>
                                                      </FileContainer>
                                                    )
                                              })
                                      }   
                                      </div>
                                )
                            }
                    })()

        }
        <span onClick={this.openFileDialog} style={{ cursor: this.props.disabled ? "default" : "pointer", fontSize:"14px" }}>Add an attachment</span>
      </div>
    );
  }
}

export default Dropzone;